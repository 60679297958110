import PropTypes from 'prop-types';
import React from 'react';

export default class WarningMessage extends React.Component {
  static propTypes = {
    message: PropTypes.string.isRequired,
  };
  
  render() {
    const { message } = this.props;
    
    return (
      <div className="flex items-center gap-2 rounded-lg border border-[#F2C253] bg-[#F2C253]/10 px-3 py-3 mb-3">
        <span className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-[#F2C253] text-white">
          <svg className="h-4 w-4" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99935 8V11.3333" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.99868 5.33366C7.90668 5.33366 7.83202 5.40833 7.83268 5.50033C7.83268 5.59233 7.90735 5.66699 7.99935 5.66699C8.09135 5.66699 8.16602 5.59233 8.16602 5.50033C8.16602 5.40833 8.09135 5.33366 7.99868 5.33366" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.6667 14H3.33333C2.59667 14 2 13.4033 2 12.6667V3.33333C2 2.59667 2.59667 2 3.33333 2H12.6667C13.4033 2 14 2.59667 14 3.33333V12.6667C14 13.4033 13.4033 14 12.6667 14Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </span>
        <span className="flex items-center text-sm text-[#BD880E]"> { message } </span>
      </div>
    )
  }
}
