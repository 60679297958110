import React, {useState, useEffect} from "react";
import {queryFilter} from "../../utils/filterHelper";

const ListItem = ({
                    obj,
                    onCheck,
                    targetLanguageCodes,
                    prefix = "normal",
                  }) => {
  return (
    <li className="flex items-center cursor-pointer" onClick={() => onCheck(obj.value)}>
      <span type="checkbox" className={`${targetLanguageCodes.includes(obj.value) ? "checked-span " : ""} text-success-green focus:ring-success-green h-5 w-5 rounded border-gray-300 bg-white focus:ring-2 cursor-pointer`}></span>
      <label className="ml-2 text-sm text-gray-700 cursor-pointer">
        {obj.label}
      </label>
    </li>
  );
};

function applyFilter(languages, query) {
  return languages.filter((obj) => {
    return query.includes(obj.value) || queryFilter(obj.label, query);
  });
}

const TargetLanguagesModalContent = ({
                                       targetLanguageCodes,
                                       targetLanguages,
                                       highlightedTargetLanguages,
                                       addToTargetLanguages,
                                       removeTargetLanguages,
                                       setOpenTargetLanguagesModal,
                                     }) => {
  const [query, setQuery] = useState("", "");
  
  const onCheck = (code) => {
    if (targetLanguageCodes.includes(code)) {
      removeTargetLanguages([code]);
    } else {
      addToTargetLanguages([code]);
    }
  };
  
  targetLanguages = applyFilter(targetLanguages, query);
  highlightedTargetLanguages = applyFilter(highlightedTargetLanguages, query);
  
  return (
    <React.Fragment>
      <div className="relative h-full w-full max-w-4xl lg:h-auto">
        <div className="relative bg-white p-4 md:p-6 lg:rounded-lg">
          <div className="mb-6 flex items-center justify-between">
            <h3 className="text-base font-semibold">
              {I18n.languagePairSelectSection.language}
            </h3>
            <button
              type="button"
              className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
              onClick={() => setOpenTargetLanguagesModal(undefined)}
            >
              <svg
                aria-hidden="true"
                className="h-5 w-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">
                {I18n.languagePairSelectSection.closeModal}
              </span>
            </button>
          </div>
          <div>
            <div className="mb-6">
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <svg
                    aria-hidden="true"
                    className="h-5 w-5 text-gray-700"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    ></path>
                  </svg>
                </div>
                <input
                  type="search"
                  // value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  className="focus:border-primary focus:ring-primary block w-full rounded-lg border border-gray-300 bg-white p-4 pl-10 text-base text-gray-700"
                  placeholder={`${I18n.languagePairSelectSection.translateTo}...`}
                />
              </div>
            </div>
            {highlightedTargetLanguages.length > 0 && (
              <>
                <div>
                  <h6 className="mb-6 font-medium text-gray-700">
                    {I18n.languagePairSelectSection.topLanguages}
                  </h6>
                  <ul className="grid grid-cols-3 gap-5">
                    {highlightedTargetLanguages.map((obj, index) => {
                      return (
                        <ListItem
                          key={index}
                          prefix={"highlighted"}
                          obj={obj}
                          onCheck={onCheck}
                          targetLanguageCodes={targetLanguageCodes}
                        />
                      );
                    })}
                  </ul>
                </div>
                <hr className="my-6 border-gray-300"/>
              </>
            )}
            
            <ul className="mb-6 grid grid-cols-3 gap-5">
              {targetLanguages
              .filter((obj) => !highlightedTargetLanguages.includes(obj))
              .map((obj, index) => {
                return (
                  <ListItem
                    key={index}
                    obj={obj}
                    onCheck={onCheck}
                    targetLanguageCodes={targetLanguageCodes}
                  />
                );
              })}
            </ul>
            
            <div className="flex items-center justify-end gap-4">
              <button
                type="button"
                onClick={() => removeTargetLanguages(targetLanguageCodes)}
                className="inline-flex items-center justify-center rounded-lg bg-custom-dark-black/5 px-6 py-3 text-sm font-medium text-custom-dark-black focus:outline-none focus:ring-2 focus:ring-custom-dark-black/10 focus:ring-offset-2"
              >
                {I18n.languagePairSelectSection.resetAll}
              </button>
              <button
                type="button"
                onClick={() => setOpenTargetLanguagesModal(undefined)}
                className="bg-primary focus:ring-primary/80 inline-flex items-center justify-center rounded-lg px-6 py-3 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                {I18n.languagePairSelectSection.submit}
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default TargetLanguagesModalContent;
