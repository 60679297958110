String.prototype.interpolate = function(params) {
  const names = Object.keys(params);
  const vals = Object.values(params);
  return new Function(...names, `return \`${this}\`;`)(...vals);
}

export const stringToHtml = (encodedHtml, variables = {}) => {
  const ele = document.createElement('div');
  ele.innerHTML = encodedHtml;
  const template = ele.textContent;
  return  template.interpolate(variables);
};
