import React  from 'react';
import {stringToHtml} from "../../utils/stringHelper";
import {parseToFixed, showPrice} from "../utils/order";
import WarningMessage from "./WarningMessage";

import Moment from 'moment/min/moment-with-locales';
import MomentLocalizer from 'react-widgets-moment';

export const OrderSummary = ({locale, backLink, processing, i18n, tmpOrder, form, selection, selectedPackage, errorMessages, handleSubmit, submitText, couponCodeRenderer = <></>}) => {
  const { currency } = form;
  
  Moment.locale(locale);
  MomentLocalizer.constructor();
  
  return (
    <div className="fixed bottom-0 left-0 w-full lg:static">
      <div className="sticky inset-4 rounded-lg bg-white p-4 shadow-[0_3px_8px_2px_rgba(97,89,255,0.1)] md:p-6 lg:mb-6">
        <div className="hidden lg:block">
          <div data-active-classes="bg-white text-gray-900" data-inactive-classes="text-custom-gg">
            <h2 id="accordion-heading-2" className="text-custom-gg">
              <button data-controller="accordion" type="button"
                      className="flex w-full items-center justify-between border-0 text-sm"
                      data-accordion-target="#accordion-body-2" aria-expanded="false" aria-controls="accordion-body-2">
                      <span>
                        {i18n.translationDetails}
                      </span>
                <svg data-accordion-icon className="h-6 w-6 shrink-0" fill="currentColor" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"></path>
                </svg>
              </button>
            </h2>
            <div id="accordion-body-2" className="hidden" aria-labelledby="accordion-heading-2">
              <div className="pt-4">
                <ul className="mb-5 space-y-2.5">
                  <li className="flex items-center justify-between gap-1 text-sm">
                    <span className="text-custom-gg">{i18n.documents}</span>
                    <div className="item-center flex gap-2">
                    <span data-controller="tooltip"
                          className="max-w-[110px] truncate font-medium text-custom-dark-black"
                          data-tooltip-target="tooltip-first-doc" data-tooltip-placement="bottom">
                      {tmpOrder.order_files[0].file_name}
                    </span>
                      {tmpOrder.order_files.length > 1 &&
                        <span
                          className="inline-flex items-center justify-center rounded-lg bg-gray-100 py-1 px-2 text-xs text-gray-500"
                          data-controller="tooltip"
                          data-tooltip-target="tooltip-multiple-doc"
                          data-tooltip-placement="bottom"
                          dangerouslySetInnerHTML={{__html: stringToHtml(i18n.documentsMore, {val: tmpOrder.order_files.length - 1})}}>
                      </span>
                      }
                    </div>
                    <div id="tooltip-first-doc" role="tooltip"
                         className="tooltip invisible absolute z-10 inline-block rounded-lg bg-gray-900 px-3 py-2 text-xs font-medium text-white opacity-0 shadow-sm transition-opacity duration-300">
                      {tmpOrder.order_files[0].file_name}
                    </div>
                    {tmpOrder.order_files.length > 1 &&
                      <div id="tooltip-multiple-doc" role="tooltip"
                           className="tooltip invisible absolute z-10 inline-block rounded-lg bg-gray-900 px-3 py-2 text-xs font-medium text-white opacity-0 shadow-sm transition-opacity duration-300">
                        <ul className="space-y-1">
                          {tmpOrder.order_files.slice(1).map((obj, index) => {
                            return (
                              <li key={index} className='truncate w-48'>{obj.file_name}</li>
                            )
                          })
                          }
                        </ul>
                      </div>
                    }
                  </li>
                  <li className="flex items-center justify-between gap-1 text-sm">
                    <span className="text-custom-gg">{i18n.serviceType}</span>
                    <span className="font-medium text-custom-dark-black">{selection.service_type}</span>
                  </li>
                  <li className="flex items-center justify-between gap-1 text-sm">
                    <span className="text-custom-gg">{i18n.contentType}</span>
                    <span className="font-medium text-custom-dark-black">{selection.translation_expert}</span>
                  </li>
                  <li className="flex items-center justify-between gap-1 text-sm">
                    <span className="text-custom-gg">{i18n.translateFrom}</span>
                    <span className="font-medium text-custom-dark-black">{selection.source_language}</span>
                  </li>
                  <li className="flex items-center justify-between gap-1 text-sm">
                    <span className="text-custom-gg">{i18n.translateTo}</span>
                    <span className="font-medium text-custom-dark-black">{selection.target_languages.join(', ')}</span>
                  </li>
                  <li className="flex items-center justify-between gap-1 text-sm">
                    <span className="text-custom-gg">{i18n.totalWordCount}</span>
                    <span className="font-medium text-custom-dark-black"
                          dangerouslySetInnerHTML={{__html: stringToHtml(i18n.words, {val: form.translation_unit_count})}}>
                          </span>
                  </li>
                </ul>
              </div>
            </div>
            <hr className="my-4 border-custom-dark-black/5"/>
            <h2 id="accordion-heading-3">
              <button data-controller="accordion" type="button"
                      className="flex w-full items-center justify-between border-0 text-sm"
                      data-accordion-target="#accordion-body-3" aria-expanded="true" aria-controls="accordion-body-3">
                <span>{i18n.packageDetails}</span>
                <svg data-accordion-icon className="h-6 w-6 shrink-0" fill="currentColor" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"></path>
                </svg>
              </button>
            </h2>
            <div id="accordion-body-3" className="" aria-labelledby="accordion-heading-3">
              <div className="pt-4">
                <ul className="space-y-4">
                  <li className="flex items-center justify-between text-sm">
                    <span className="text-custom-gg">{i18n.translationSpeed}</span>
                    <span
                      className="font-medium text-custom-dark-black">{i18n[`delivery_speed_${form.delivery_speed}`]}</span>
                  </li>
                  <li className="flex items-center justify-between text-sm">
                    <span className="text-custom-gg">{i18n.package}</span>
                    <span className="font-medium text-custom-dark-black">{selectedPackage.name}</span>
                  </li>
                  {form.additional_service_prices.length > 0 &&
                    <li className="flex items-center justify-between text-sm">
                            <span className="text-custom-gg">
                              {i18n.additional}
                            </span>
                      <span className="font-medium text-custom-dark-black">
                              {form.additional_service_prices.map(a => i18n[`additional_service_${a.name}`]).join(', ')}
                            </span>
                    </li>
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-4 hidden border-custom-dark-black/5 lg:block"/>
        <div id="priceDetail" className="hidden lg:block" data-controller="price-detail">
          <ul className="space-y-4">
            <li className="flex items-center justify-between text-sm">
              <span className="text-custom-gg">
                {i18n.subtotal}
              </span>
              <span className="font-medium text-custom-dark-black">{showPrice(form.payment_amount, currency)}</span>
            </li>
            {form.discount_amount && parseFloat(form.discount_amount) > 0 &&
              <li className="flex items-center justify-between text-sm">
                <span className="text-custom-gg">{i18n.discount}</span>
                <span className="text-success-green font-medium">-{ parseToFixed((form.discount_amount*100)/form.payment_amount) }% ({showPrice(form.discount_amount, currency)})</span>
              </li>
            }
            {form.tax_rate > 0 &&
              <li className="flex items-center justify-between text-sm">
                <span className="text-custom-gg">{i18n.vat}({form.tax_rate}%)</span>
                <span className="font-medium text-custom-dark-black">{showPrice(form.tax_amount, currency)}</span>
              </li>
            }
            {/*<li className="flex items-center justify-between text-sm">*/}
            {/*  <span className="text-custom-gg">Shipping</span>*/}
            {/*  <span className="font-medium text-custom-dark-black">$2.00</span>*/}
            {/*</li>*/}
            {form.additional_service_amount > 0 &&
              <li className="flex items-center justify-between text-sm">
                      <span className="text-custom-gg">
                        {i18n.additionalServices}
                      </span>
                <span
                  className="font-medium text-custom-dark-black">{showPrice(form.additional_service_amount, currency)}</span>
              </li>
            }
          </ul>
          <hr className="my-4 border-custom-dark-black/5"/>
          <ul className="space-y-4">
            <li className="flex items-center justify-between text-sm">
                    <span className="text-custom-gg">
                      {i18n.estimatedDelivery}
                    </span>
              <span className="font-extrabold text-custom-dark-black">
                      {Moment(form.customer_delivery_date_at).format(`LL`)}
                    </span>
            </li>
          </ul>
          <hr className="my-4 border-custom-dark-black/5"/>
          {couponCodeRenderer}
          {
            errorMessages && errorMessages.slice(0, 2).map((error, index) =>
              <WarningMessage key={index} message={error}/>
            )
          }
        </div>
        
        <div className="flex items-center justify-between gap-2 rounded-lg bg-[linear-gradient(0deg,_#F4F3FF,_#F4F3FF)] px-3 py-4 lg:mb-4">
          <span className="text-xl font-medium text-[#908BFF]">
            {i18n.total}
          </span>
          <span id="mobileDetailPrice" className="text-primary flex items-center gap-2 text-2xl font-medium">
            {showPrice(form.total_payment_amount, currency)}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" className="h-4 w-4 transition duration-300 ease-linear lg:hidden">
              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5"/>
            </svg>
          </span>
        </div>
        
        <div className="hidden items-center justify-between gap-2 lg:flex">
          <a href={backLink}
             disabled={processing}
             data-turbo-method="post"
             data-turbo-frame="_top"
             className="inline-flex items-center justify-center gap-2 rounded-lg bg-custom-dark-black/5 px-6 py-3 text-sm font-medium text-custom-dark-black focus:outline-none focus:ring-2 focus:ring-custom-dark-black/10 focus:ring-offset-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                 stroke="currentColor" className="mr-2 h-5 w-5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"/>
            </svg>
            {i18n.back}
          </a>
          <button disabled={processing} onClick={handleSubmit} type="button"
                  className="bg-primary focus:ring-primary/80 flex w-full items-center justify-center gap-2 rounded-lg px-6 py-3 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2">
            {submitText}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                 stroke="currentColor" className="h-5 w-5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
};

export default OrderSummary;