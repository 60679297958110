import { Controller } from "@hotwired/stimulus"
import { Dropdown } from "flowbite";

export default class extends Controller {
  connect() {
    // console.log("DropdownController controller")
    const element = this.element;
    const dropdownId = element.getAttribute('data-dropdown-toggle');
    const dropdownEl = document.getElementById(dropdownId);
  
    // const placement = element.getAttribute('data-dropdown-placement');
    // const offsetSkidding = element.getAttribute('data-dropdown-offset-skidding');
    // const offsetDistance = element.getAttribute('data-dropdown-offset-distance');
    // const triggerType = element.getAttribute('data-dropdown-trigger');
    // const delay = element.getAttribute('data-dropdown-delay');
    
    let dropdown = new Dropdown(dropdownEl, element, {
      placement: 'bottom',
      delay: 10,
    })
  }
}
