import { Controller } from "@hotwired/stimulus"
import ReactDOM from "react-dom";
import React from "react";

export default class extends Controller {
  connect() {
    const element = this.element;
    const className = element.getAttribute(ReactRailsUJS.CLASS_NAME_ATTR);
    const constructor = ReactRailsUJS.getConstructor(className);
    const propsJson = element.getAttribute(ReactRailsUJS.PROPS_ATTR);
    const props = propsJson && JSON.parse(propsJson);

    // Improvement:
    // Was this component already rendered? Just hydrate it with the props coming in.
    // This is currently acceptable since all our components are expected to be reset
    // on page navigation.
    const component = React.createElement(constructor, props)
    ReactDOM.render(component, element)
  }

  disconnect() {
    // console.log("react disconnected")
  }
}
