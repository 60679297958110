import PropTypes from "prop-types";
import React from "react";

import UploadedFileList from "./components/UploadedFileList";

// Actions
import UploadActions from "./actions/UploadActions";

export default class FileUploadStep extends React.Component {
  static propTypes = {
    orderUUID: PropTypes.string.isRequired,
    textUploads: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      error: null,
      text: "",
      uploadedFiles: props.textUploads,
      // uploadedFiles: [
      //   // {file_name: "aa", file_size: 1000},
      // ],
    };
  }

  onClickSend = () => {
    this.setState({
      error: null,
      processing: true,
    });
    const { orderUUID } = this.props;
    const { text } = this.state;

    const object = {
      id: Date.now(),
      name: text.substring(0, 50),
      text,
    };
    UploadActions.uploadText(object, orderUUID, (json) => {
      if (json.success) {
        this.setState({
          uploadedFiles: this.state.uploadedFiles.concat([
            {
              file_name: json.file_name,
              file_size: json.file_size,
              upload_id: json.upload_id,
            },
          ]),
          text: "",
          processing: false,
        });
        PushData.pushTrack("text_uploaded");
        document.getElementById('action-button-next').disabled = false;
      } else {
        this.setState({
          error: json.error,
          processing: false,
        });
        PushData.pushTrack("text_upload_failed");
      }
    });
  };

  handleRemoveFileResult = (removedFile) => (json) => {
    if (json.success) {
      const uploadedFiles = this.state.uploadedFiles.filter(
        (file) => file.upload_id !== removedFile.upload_id
      );
      this.setState({
        uploadedFiles,
        processing: false,
      });
    } else {
      alert(json.error);
      this.setState({
        processing: false,
      });
    }
  };

  removeFile(index) {
    const { orderUUID } = this.props;
    this.setState({
      processing: true,
    });

    const removedFile = this.state.uploadedFiles[index];
    UploadActions.delete(
      removedFile,
      orderUUID,
      this.handleRemoveFileResult(removedFile)
    );
  }

  render() {
    const { uploadedFiles, text, error, processing } = this.state;

    return (
      <div className="flex flex-col-reverse md:flex-col gap-6">
        <div className="rounded-lg bg-white p-4 shadow-custom md:p-6">
          <h2 className="mb-4 text-lg font-semibold tracking-wide text-custom-black">
            {I18n.fileUploadSection.typeText}
          </h2>
          <form>
            <div className="mb-4">
              <textarea
                id="message"
                rows="4"
                value={text}
                onChange={(e) => this.setState({ text: e.target.value })}
                className="focus:border-primary focus:ring-primary block w-full resize-none rounded-lg border border-custom-gray bg-white p-4 text-base text-gray-900 placeholder:text-gray-500"
                placeholder={I18n.fileUploadSection.typeTextPlaceholder}
              ></textarea>
              {error && (
                <div className="flex items-center gap-2 rounded-lg border border-custom-orange bg-custom-orange/10 px-3 py-3 mt-2">
                  <span className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-custom-orange text-white">
                    <svg
                      className="h-4 w-4"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.99935 8V11.3333"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M7.99868 5.33366C7.90668 5.33366 7.83202 5.40833 7.83268 5.50033C7.83268 5.59233 7.90735 5.66699 7.99935 5.66699C8.09135 5.66699 8.16602 5.59233 8.16602 5.50033C8.16602 5.40833 8.09135 5.33366 7.99868 5.33366"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.6667 14H3.33333C2.59667 14 2 13.4033 2 12.6667V3.33333C2 2.59667 2.59667 2 3.33333 2H12.6667C13.4033 2 14 2.59667 14 3.33333V12.6667C14 13.4033 13.4033 14 12.6667 14Z"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </span>
                  <span className="flex items-center text-sm text-[#BD880E]">
                    {error}
                  </span>
                </div>
              )}
            </div>
            <div className="flex items-center justify-end gap-4">
              <button
                type="button"
                onClick={(e) => this.setState({ text: "" })}
                disabled={text.toString().length === 0}
                className="inline-flex w-full items-center justify-center rounded-lg bg-custom-dark-black/5 px-6 py-3 text-sm font-medium text-custom-dark-black focus:outline-none focus:ring-2 focus:ring-custom-dark-black/10 focus:ring-offset-2 sm:w-auto"
              >
                {I18n.fileUploadSection.cancel}
              </button>
              <button
                type="button"
                disabled={text.toString().length === 0 || processing}
                onClick={this.onClickSend.bind(this)}
                className="bg-primary focus:ring-primary/80 inline-flex w-full items-center justify-center rounded-lg px-6 py-3 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto"
              >
                {I18n.fileUploadSection.send}
              </button>
            </div>
          </form>
        </div>

        {uploadedFiles.length > 0 && (
          <div className="rounded-lg bg-white p-4 shadow-custom md:p-6">
            <h2 className="mb-4 text-lg font-semibold tracking-wide text-custom-black">
              {I18n.fileUploadSection.typedTexts}
            </h2>

            <UploadedFileList
              files={uploadedFiles}
              processing={processing}
              removeFile={this.removeFile.bind(this)}
            />
          </div>
        )}
      </div>
    );
  }
}
