import PropTypes from 'prop-types';
import React from 'react';

// Utils
import {
  showPrice,
  getBusinessDayFromHours
} from './utils/order';
import {stringToHtml} from "../utils/stringHelper";

import {
  DELIVERY_TYPE_CARGO,
  DELIVERY_TYPE_COURIER,
  DELIVERY_TYPE_SCANNED,
  PACKAGE_STANDARD,
  PRICE_REQUIRED_DELIVERY_TYPES,
  DELIVERY_TYPE_NONE,
  DOMAIN_HIZLICEVIRI,
  DELIVERY_SPEED_STANDARD,
  DELIVERY_SPEED_FAST
} from "./utils/OrderConstants";

import OrderActions from "./actions/OrderActions";

import WarningMessage from "./components/WarningMessage";
import OrderSummary from "./components/OrderSummary";
import HoursFaster from "./components/HoursFaster";

function getSelectedPackage(packages, form) {
  return packages.filter(a => a.id === parseInt(form.package_id))[0];
}

function fetchAdditionalServiceStates(packageKey) {
  if (packageKey === PACKAGE_STANDARD) {
    return {
      certifiedDisabled: true,
      notarizedDisabled: true
    }
  }
  
  return {
    certifiedDisabled: false,
    notarizedDisabled: false
  }
}

export default class PackageAndServicesStep extends React.Component {
  static propTypes = {
    orderUUID: PropTypes.string.isRequired,
    tmpOrder: PropTypes.object.isRequired,
    domain: PropTypes.object.isRequired,
    selection: PropTypes.object.isRequired,
    packages: PropTypes.array.isRequired,
    postUrl: PropTypes.string.isRequired,
    backLink: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    // console.log(props.tmpOrder)
    // console.log(props.tmpOrder.calculations)
    // console.log(props.packages)
    
    let extraStates = {};
    const selectedPackage = props.packages.filter(a => a.id === props.tmpOrder.package_id)[0];
    if(selectedPackage) {
      extraStates = fetchAdditionalServiceStates(selectedPackage.key);
    }
    
    this.state = {
      ...extraStates,
      processing: false,
      form: {
        ...props.tmpOrder
      },
      errorMessages: [
        // "Test error message",
        // "Test error message 2",
      ]
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChangeForAdditionalService = this.handleInputChangeForAdditionalService.bind(this);
    this.setForm = this.setForm.bind(this);
  }

  handleSubmit(event) {
    this.setState({
      processing: true
    });
    OrderActions.complete(this.props.postUrl, this.state.form, (json) => {
      if (json.success) {
        // this.setState({
        //   processing: false
        // });
        Turbo.visit(json.data.path, { action: 'replace' })
      } else {
        let errorMessages = json.error_messages;
        if(json.error) {
          errorMessages.push(json.error)
        }

        this.setState({
          processing: false,
          errorMessages,
        });
      }
    })
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const newForm = { ...this.state.form, [name]: value };
    let extraStates = {}
    
    if(name === "package_id"){
      PushData.pushTrack("package_selected", {package_type: target.dataset.name})
      newForm.is_certified_translation = false;
      newForm.is_notarised = false;
      newForm.delivery_type = DELIVERY_TYPE_NONE;
      newForm.delivery_speed = DELIVERY_SPEED_STANDARD;
      
      extraStates = fetchAdditionalServiceStates(target.dataset.key);
    } else if(name === "delivery_speed") {
      PushData.pushTrack("speed_selected", {speed_type: value})
    }
    
    this.setForm(newForm, extraStates);
  }

  handleInputChangeForAdditionalService(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let newForm = { ...this.state.form, [name]: value };
    let extraStates = {}

    if(name === 'is_certified_translation') {
      if (value) {
        extraStates = { notarizedDisabled: true }
        newForm.delivery_type = DELIVERY_TYPE_SCANNED;
      } else {
        extraStates = { notarizedDisabled: false }
        newForm.delivery_type = DELIVERY_TYPE_NONE;
      }
    } else if (name === 'is_notarised') {
      if (value) {
        extraStates = { certifiedDisabled: true }
        newForm.is_certified_translation = true;
        newForm.delivery_type = DELIVERY_TYPE_CARGO;
      } else {
        extraStates = { certifiedDisabled: false }
        newForm.is_certified_translation = false;
        newForm.delivery_type = DELIVERY_TYPE_NONE;
      }
    }
    
    if(name === "delivery_type"){
      PushData.pushTrack("delivery_selected", {delivery_type: value})
    } else {
      PushData.pushTrack("additional_service", {notarised: newForm.is_notarised, certified_translation: newForm.is_certified_translation})
    }

    this.setForm(newForm, extraStates);
  }

  setForm(newForm, extraStates = {}) {
    const {packages, tmpOrder} = this.props;
    const {calculations} = tmpOrder;

    const selectedPackage = getSelectedPackage(packages, newForm);
    const packageCalculations = calculations.packages;
    const selectedPackageResult = packageCalculations[selectedPackage.id];
    const result = selectedPackageResult[newForm.delivery_speed];
    let paymentAmount = parseFloat(result.payment_amount);
    let additionalServiceAmount = 0;
    let additionalServicePrices = [];

    if(newForm.is_notarised) {
      const amount = parseFloat(calculations.notarised_translation)
      additionalServiceAmount += amount
      additionalServicePrices.push({name: "notarised_translation", amount: amount})
    }

    if(newForm.is_certified_translation) {
      const amount = parseFloat(calculations.certified_translation);
      additionalServiceAmount += amount;
      additionalServicePrices.push({name: "certified_translation", amount: amount})
    }

    if(PRICE_REQUIRED_DELIVERY_TYPES.includes(newForm.delivery_type)) {
      const amount = parseFloat(calculations.delivery_type[newForm.delivery_type])
      additionalServiceAmount += amount
      additionalServicePrices.push({name: "delivery_type", amount: amount})
    }
    paymentAmount = paymentAmount + additionalServiceAmount
    const taxAmount = (paymentAmount * newForm.tax_rate) / 100.0;


    this.setState({
      ...extraStates,
      form: {
        ...newForm,
        additional_service_prices: additionalServicePrices,
        additional_service_amount: additionalServiceAmount,
        tax_amount: taxAmount,
        payment_amount: paymentAmount,
        customer_delivery_date_at: result.customer_delivery_date_at,
        total_payment_amount: taxAmount + paymentAmount,
      },
      errorMessages: []
    })
  }

  render() {
    const {packages, backLink, tmpOrder, selection, domain} = this.props;
    const {calculations} = tmpOrder;
    const {processing, notarizedDisabled, certifiedDisabled, form, errorMessages} = this.state;
    const { currency } = form;
    const selectedPackage = getSelectedPackage(packages, form);
    const packageCalculations = calculations.packages;
    const selectedPackageResult = packageCalculations[selectedPackage.id];
    const fastTranslationHourDiff = parseInt(selectedPackageResult.fast.customer_translation_hour - selectedPackageResult.standard.customer_translation_hour);
    const fastTranslationPrice = parseFloat(selectedPackageResult.fast.payment_amount) - parseFloat(selectedPackageResult.standard.payment_amount);
    const enableFastDelivery = selectedPackageResult.fast.split_exists ? fastTranslationPrice > 0 : false;
    const unchargedWords = calculations.uncharged_word_count || 0;

    const displayScannedDelivery = form.is_certified_translation && !form.is_notarised
    
    return (
      <div className="w-full max-w-screen-xl mx-auto">
        <div className="grid gap-8 lg:grid-cols-[1fr,350px]">
          <div className="space-y-6">
  
            { unchargedWords > 10  &&
              <div className="bg-primary relative h-[105px] overflow-hidden rounded-lg">
                <img src="/images_v2/rectangle.svg" className="absolute top-0 left-0 h-full w-full object-cover opacity-20" alt=""/>
                <div className="flex h-full w-full items-center justify-center gap-3 px-3 xl:justify-between xl:px-6">
                  <img src="/images_v2/document.svg" className="mt-[22px] hidden xl:block" alt=""/>
                  <div className="text-center text-white">
                    <h2 className="text-xl font-semibold"
                        dangerouslySetInnerHTML={{__html: stringToHtml(I18n.packageSection.unchargedWords, {words: unchargedWords})}} />
                    <p className="text-sm">{I18n.packageSection.discountInfo}</p>
                  </div>
                  <img src="/images_v2/hare.svg" className="mt-[11px] hidden xl:block" alt=""/>
                </div>
              </div>
            }

            {/*<div className="border-primary bg-primary/10 flex items-center gap-2 rounded-lg border px-3 py-3">*/}
            {/*  <span className="bg-primary inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-full text-white">*/}
            {/*    <svg className="h-4 w-4" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*      <path d="M8.00033 8V11.3333" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>*/}
            {/*      <path d="M7.99966 5.33317C7.90766 5.33317 7.83299 5.40784 7.83366 5.49984C7.83366 5.59184 7.90833 5.6665 8.00033 5.6665C8.09233 5.6665 8.16699 5.59184 8.16699 5.49984C8.16699 5.40784 8.09233 5.33317 7.99966 5.33317" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>*/}
            {/*      <path fillRule="evenodd" clipRule="evenodd" d="M12.6667 14H3.33333C2.59667 14 2 13.4033 2 12.6667V3.33333C2 2.59667 2.59667 2 3.33333 2H12.6667C13.4033 2 14 2.59667 14 3.33333V12.6667C14 13.4033 13.4033 14 12.6667 14Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>*/}
            {/*    </svg>*/}
            {/*  </span>*/}
            {/*  <span className="text-primary flex items-center text-sm font-medium"> Minimum ücret için info alanı. </span>*/}
            {/*</div>*/}

            {/* Package selection */}
            <div className="rounded-lg bg-white p-4 shadow-[0_3px_8px_2px_rgba(97,89,255,0.1)] md:p-6">
              <h2 className="mb-4 text-lg font-semibold tracking-wide text-custom-black">
                { I18n.packageSection.packages }
              </h2>
              <fieldset>
                <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 md:gap-5 xl:grid-cols-3">
                  { packages.map((p, index) => {
                    const packageKey = `${p.id}-${p.key}-packages`;
                    const packageResult = packageCalculations[p.id]['standard'];

                    return(
                      <div className="relative" key={index}>
                        <input className="peer sr-only"
                               defaultChecked={form.package_id === p.id}
                               type="radio"
                               value={p.id}
                               data-name={p.name}
                               data-key={p.key}
                               name="package_id"
                               id={packageKey}
                               onChange={this.handleInputChange}
                        />
                        <label htmlFor={packageKey} className="flex h-full cursor-pointer rounded-lg border border-custom-dark-black/10 bg-white p-4 hover:bg-gray-50 focus:outline-none peer-checked:border-success-green peer-checked:ring-4 peer-checked:ring-success-green/30 md:p-5">
                          <div className="flex flex-col justify-between">
                            <div className="md:space-y-3">
                              <img src={`/images_v2/packages/${p.key}.svg`} className="hidden h-10 w-10 md:block"/>
                              <div className="space-y-6">
                                <div className="flex items-center gap-4">
                                  <img src={`/images_v2/packages/${p.key}.svg`} className="block h-12 w-12 md:hidden"/>
                                  <div>
                                    <h6 className="text-lg font-semibold text-custom-blue-g md:text-xl"> { p.name } </h6>
                                    <p className="text-xl font-medium text-custom-dark-black md:text-2xl">
                                      { showPrice(packageResult.payment_amount, currency) }
                                    </p>
                                  </div>
                                </div>
                                <p className="text-sm text-[#656474] md:min-h-[80px]">
                                  { p.short_description }
                                </p>
                                <hr className="hidden border-[#E9E9F0] md:block"/>
                                <ul className="hidden space-y-2 md:block">
                                  { p.descriptions.map((item, index) => {
                                    return(
                                      <li key={index} className="flex items-start gap-3 text-sm leading-6 text-[#656474]">
                                        <span className="mt-1 inline-flex items-center justify-center">
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-4 w-4 shrink-0">
                                            <path fill="none" d="M0 0h24v24H0z"/>
                                            <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor"/>
                                          </svg>
                                        </span>
                                        { item }
                                      </li>
                                    )
                                  }) }
                                </ul>
                              </div>
                            </div>
                            <span className="bg-primary focus:ring-primary/80 mt-6 flex w-full items-center justify-center gap-2 rounded-lg px-6 py-3 text-sm font-medium text-white hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 md:mt-8">
                              { I18n.packageSection.select }
                            </span>
                          </div>
                        </label>
                        <svg className="absolute top-5 right-5 hidden h-6 w-6 text-success-green peer-checked:block" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd"/>
                        </svg>
                      </div>
                    )
                  })
                  }
                </div>
              </fieldset>
            </div>

            {/* Speed selection */}
            <div className="rounded-lg bg-white p-4 shadow-[0_3px_8px_2px_rgba(97,89,255,0.1)] md:p-6">
              <h2 className="mb-4 text-lg font-semibold tracking-wide text-custom-black">
                { I18n.packageSection.translationSpeed }
              </h2>
              <fieldset>
                <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 md:gap-5 lg:grid-cols-3">
                  <div className="relative">
                    <input checked={form.delivery_speed === DELIVERY_SPEED_STANDARD}
                           value="standard"
                           name="delivery_speed"
                           onChange={this.handleInputChange}
                           className="peer sr-only"
                           type="radio"
                           id="standard-translation-speed"/>
                    <label className="flex h-full cursor-pointer rounded-lg border border-custom-dark-black/10 bg-white p-4 hover:bg-gray-50 focus:outline-none peer-checked:border-success-green peer-checked:ring-4 peer-checked:ring-success-green/30 md:p-5" htmlFor="standard-translation-speed">
                      <div className="flex w-full flex-row gap-3 md:flex-col">
                        <img src="/images_v2/icons/translation_speed/standard.svg" className="h-10 w-10"/>
                        <div>
                          <div className="mb-3">
                            <h6 className="text-xl font-semibold text-custom-blue-g"> { I18n.packageSection.speedStandard } </h6>
                            <p className="text-xs font-medium text-custom-dark-black"
                               dangerouslySetInnerHTML={{__html: stringToHtml(I18n.packageSection.inBusinessDays, {val: getBusinessDayFromHours(selectedPackageResult.standard.customer_translation_hour)})}}>
                            </p>
                          </div>
                          <p className="flex flex-wrap items-center justify-between gap-2 text-xl font-medium text-custom-gg">
                            { showPrice(0, currency) }
                            {/*{ fastTranslationHourDiff > 0 &&*/}
                            {/*  <HoursFaster diff={fastTranslationHourDiff}/>*/}
                            {/*}*/}
                          </p>
                        </div>
                      </div>
                    </label>
                    <svg className="absolute top-5 right-5 hidden h-6 w-6 text-success-green peer-checked:block" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd"/>
                    </svg>
                  </div>
                  <div className={ enableFastDelivery ? "relative" : "pointer-events-none relative"}>
                    <input checked={form.delivery_speed === DELIVERY_SPEED_FAST}
                           value="fast"
                           name="delivery_speed"
                           onChange={this.handleInputChange}
                           className="peer sr-only"
                           type="radio"
                           id="fast-translation-speed"/>
                    <label className={`flex h-full cursor-pointer rounded-lg border border-custom-dark-black/10 ${enableFastDelivery ? "bg-white" : "bg-gray-50"} p-4 hover:bg-gray-50 focus:outline-none peer-checked:border-success-green peer-checked:ring-4 peer-checked:ring-success-green/30 md:p-5`} htmlFor="fast-translation-speed">
                      <div className="flex w-full flex-row gap-3 md:flex-col">
                        <img src="/images_v2/icons/translation_speed/fast.svg" className="h-10 w-10"/>
                        <div>
                          <div className="mb-3">
                            <h6 className="text-xl font-semibold text-custom-blue-g"> { I18n.packageSection.speedFast } </h6>
                            { enableFastDelivery &&
                              <p className="text-xs font-medium text-custom-dark-black"
                                 dangerouslySetInnerHTML={{__html: stringToHtml(I18n.packageSection.inBusinessDays, {val: getBusinessDayFromHours(selectedPackageResult.fast.customer_translation_hour)})}}>
                              </p>
                            }
                            { !enableFastDelivery &&
                              <p className="text-xs font-medium text-custom-gg"> { I18n.packageSection.speedFastDisabledInfo } </p>
                            }
                          </div>
                          <p className="flex flex-wrap items-center justify-between gap-2 text-xl font-medium text-custom-gg">
                            {enableFastDelivery &&
                              <>
                                { showPrice(fastTranslationPrice, currency) }
                                { fastTranslationHourDiff < 0 &&
                                  <HoursFaster diff={ -1 * fastTranslationHourDiff}/>
                                }
                              </>
                            }
                          </p>
                        </div>
                      </div>
                    </label>
                    <svg className="absolute top-5 right-5 hidden h-6 w-6 text-success-green peer-checked:block" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd"/>
                    </svg>
                  </div>
                  <div className="pointer-events-none relative">
                    <input className="peer sr-only" type="radio" value="express-translation-speed" name="translation-speed" id="express-translation-speed"/>
                    <label className="flex h-full cursor-pointer rounded-lg border border-custom-dark-black/10 bg-gray-50 p-5 hover:bg-gray-50 focus:outline-none peer-checked:border-success-green peer-checked:ring-4 peer-checked:ring-success-green/30" htmlFor="express-translation-speed">
                      <div className="flex w-full flex-row gap-3 md:flex-col">
                        <img src="/images_v2/icons/translation_speed/standard.svg" className="h-10 w-10"/>
                        <div>
                          <h6 className="text-xl font-semibold text-custom-gg"> { I18n.packageSection.speedExpress } </h6>
                          <p className="text-xs font-medium text-custom-gg"> { I18n.packageSection.speedExpressInfo } </p>
                        </div>
                      </div>
                    </label>
                    <svg className="absolute top-5 right-5 hidden h-6 w-6 text-success-green peer-checked:block" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd"/>
                    </svg>
                  </div>
                </div>
              </fieldset>
            </div>

            {/* Additional Services selection */}
            {domain.key == DOMAIN_HIZLICEVIRI &&
              <>
                <div className="rounded-lg bg-white p-4 shadow-[0_3px_8px_2px_rgba(97,89,255,0.1)] md:p-6" data-controller="additional-services">
                  <h2 className="mb-4 text-lg font-semibold tracking-wide text-custom-black">
                    { I18n.packageSection.additionalServices }
                  </h2>
                  <fieldset>
                    <div className="space-y-4">
                      <div className="relative">
                        <input checked={form.is_certified_translation}
                               onChange={this.handleInputChangeForAdditionalService}
                               name="is_certified_translation"
                               disabled={certifiedDisabled}
                               type="checkbox"
                               className="peer sr-only"
                               id="sworn-translation"/>
                        <label className="flex h-full cursor-pointer rounded-lg border border-custom-dark-black/10 bg-white p-4 hover:bg-gray-50 focus:outline-none peer-checked:border-success-green peer-checked:ring-4 peer-checked:ring-success-green/30 peer-disabled:cursor-not-allowed peer-disabled:bg-gray-50 md:p-5" htmlFor="sworn-translation">
                          <div className="flex w-full flex-col items-end justify-between gap-3 sm:flex-row sm:items-center sm:pr-12">
                            <div className="flex flex-row items-center gap-4">
                              <img src="/images_v2/icons/additional_services/sworn-translation.svg" className="h-12 w-12"/>
                              <div className="">
                                <h6 className="text-base font-semibold text-custom-blue-g">
                                  { I18n.packageSection.certifiedTranslation }
                                </h6>
                                {/*<p className="text-sm font-medium text-[#4C4B5E]"> Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet. </p>*/}
                              </div>
                            </div>
                            <p className="border-gray-300 py-2 text-xl font-semibold text-custom-dark-black sm:border-l sm:pl-5">
                              { showPrice(calculations.certified_translation, currency) }
                            </p>
                          </div>
                        </label>
                        <svg className="absolute top-5 right-5 hidden h-6 w-6 text-success-green peer-checked:block sm:top-1/2 sm:-translate-y-1/2" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd"/>
                        </svg>
                      </div>
                      <div className="relative">
                        <input checked={form.is_notarised}
                               onChange={this.handleInputChangeForAdditionalService}
                               name="is_notarised"
                               disabled={notarizedDisabled}
                               type="checkbox"
                               className="peer sr-only"
                               id="notarized"/>
                        <label className="flex h-full cursor-pointer rounded-lg border border-custom-dark-black/10 bg-white p-4 hover:bg-gray-50 focus:outline-none peer-checked:border-success-green peer-checked:ring-4 peer-checked:ring-success-green/30 peer-disabled:cursor-not-allowed peer-disabled:bg-gray-50 md:p-5" htmlFor="notarized">
                          <div className="flex w-full flex-col items-end justify-between gap-3 sm:flex-row sm:items-center sm:pr-12">
                            <div className="flex flex-row items-center gap-4">
                              <img src="/images_v2/icons/additional_services/notary.svg" className="h-12 w-12"/>
                              <div className="">
                                <h6 className="text-base font-semibold text-custom-blue-g">
                                  { I18n.packageSection.notarisedTranslation }
                                </h6>
                                {/*<p className="text-sm font-medium text-[#4C4B5E]"> Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet. </p>*/}
                              </div>
                            </div>
                            <p className="border-gray-300 py-2 text-xl font-semibold text-custom-dark-black sm:border-l sm:pl-5">
                              { showPrice(calculations.notarised_translation, currency) }
                            </p>
                          </div>
                        </label>
                        <svg className="absolute top-5 right-5 hidden h-6 w-6 text-success-green peer-checked:block sm:top-1/2 sm:-translate-y-1/2" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd"/>
                        </svg>
                      </div>
                    </div>
                  </fieldset>
                </div>

                { form.is_notarised &&
                  <WarningMessage message={ I18n.packageSection.notaryServiceInfo } />
                }

                { (form.is_notarised || form.is_certified_translation) &&
                  <div className="rounded-lg bg-white p-4 shadow-[0_3px_8px_2px_rgba(97,89,255,0.1)] md:p-6" data-controller="additional-services">
                    <h2 className="mb-4 text-lg font-semibold tracking-wide text-custom-black">
                      { I18n.packageSection.delivery }
                    </h2>
                    { displayScannedDelivery &&
                      <div id="scannedWrapper" className="relative">
                        <input id="scanned"
                               type="checkbox"
                               value={DELIVERY_TYPE_SCANNED}
                               checked={form.delivery_type === DELIVERY_TYPE_SCANNED}
                               name="delivery_type"
                               onChange={this.handleInputChangeForAdditionalService}
                               disabled
                               className="peer sr-only" />
                        <label className="flex h-full cursor-pointer rounded-lg border border-custom-dark-black/10 bg-white p-4 hover:bg-gray-50 focus:outline-none peer-checked:border-success-green peer-checked:ring-4 peer-checked:ring-success-green/30 peer-disabled:cursor-not-allowed peer-disabled:bg-gray-50 md:p-5" htmlFor="scanned">
                          <div className="flex w-full flex-row items-start gap-4 md:items-center">
                            <img src="/images_v2/icons/scanned.svg" className="h-12 w-12"/>
                            <div className="">
                              <h6 className="text-base font-semibold text-custom-blue-g"> { I18n.packageSection.deliveryScanned } </h6>
                              <p className="text-sm font-medium text-[#4C4B5E]"> { I18n.packageSection.deliveryScannedInfo } </p>
                            </div>
                          </div>
                        </label>
                        <svg className="absolute top-5 right-5 hidden h-6 w-6 text-success-green peer-checked:block sm:top-1/2 sm:-translate-y-1/2" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd"/>
                        </svg>
                      </div>
                    }

                    { !displayScannedDelivery &&
                      <div className="flex flex-col items-center gap-3 sm:flex-row md:gap-5">
                        <div className="flex w-full items-center gap-5 rounded-lg border border-gray-200 p-5">
                          <input id="cargo"
                                 type="radio"
                                 value={DELIVERY_TYPE_CARGO}
                                 checked={form.delivery_type === DELIVERY_TYPE_CARGO}
                                 name="delivery_type"
                                 onChange={this.handleInputChangeForAdditionalService}
                                 className="text-success-green focus:ring-success-green h-4 w-4 border-gray-300 bg-gray-100 focus:ring-2"/>
                          <label htmlFor="cargo" className="w-full text-base text-custom-dark-black cursor-pointer">
                            { I18n.packageSection.deliveryCargo } { showPrice(calculations.delivery_type.cargo, currency) }
                          </label>
                        </div>
                        <div className="flex w-full items-center gap-5 rounded-lg border border-gray-200 p-5">
                          <input id="courier"
                                 type="radio"
                                 value={DELIVERY_TYPE_COURIER}
                                 checked={form.delivery_type === DELIVERY_TYPE_COURIER}
                                 name="delivery_type"
                                 onChange={this.handleInputChangeForAdditionalService}
                                 className="text-success-green focus:ring-success-green h-4 w-4 border-gray-300 bg-gray-100 focus:ring-2"/>
                          <label htmlFor="courier" className="w-full text-base text-custom-dark-black cursor-pointer">
                            { I18n.packageSection.deliveryCourier } { showPrice(calculations.delivery_type.courier, currency) }
                          </label>
                        </div>
                      </div>
                    }
                  </div>
                }
              </>
            }

            {/* Bottom action links */}
            <div className="lg:hidden">
              {
                errorMessages && errorMessages.slice(0,2).map((error, index) =>
                  <WarningMessage key={index} message={error} />
                )
              }
              <div className="flex items-center justify-between gap-2">
                <a href={backLink}
                   disabled={processing}
                   data-turbo-method="post"
                   data-turbo-frame="_top"
                   className="inline-flex w-full items-center justify-center gap-2 rounded-lg bg-custom-dark-black/5 px-6 py-3 text-sm font-medium text-custom-dark-black focus:outline-none focus:ring-2 focus:ring-custom-dark-black/10 focus:ring-offset-2">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="mr-2 h-5 w-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"/>
                  </svg>
                  { I18n.packageSection.back }
                </a>
                <button disabled={processing} onClick={this.handleSubmit} type="button" className="bg-primary focus:ring-primary/80 flex w-full items-center justify-center gap-2 rounded-lg px-6 py-3 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2">
                  { I18n.packageSection.goToPayment }
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>

          {/* Order summary right bar */}
          <OrderSummary
            locale={this.props.locale}
            backLink={backLink}
            processing={processing}
            handleSubmit={this.handleSubmit}
            i18n={I18n.packageSection}
            tmpOrder={tmpOrder}
            form={form}
            selection={selection}
            selectedPackage={selectedPackage}
            errorMessages={errorMessages}
            submitText={I18n.packageSection.goToPayment}
          />
        </div>
      </div>
    );
  }
}