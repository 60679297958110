import PropTypes from 'prop-types';
import React from 'react';

export default class LanguageButtonList extends React.Component {
  static propTypes = {
    languages: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    onAdd: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
  }
  
  render() {
    const {languages, title, onAdd} = this.props;
    
    return (
      <>
        <h6 className="mb-4 text-base font-semibold text-black"> { title } </h6>
        <div id="mostPopularContent" className="mb-4 flex flex-wrap gap-2">
          {
            languages.map((obj, index) => {
              return (
                <button type="button" key={index} onClick={() => onAdd([obj.value])}>
                  <span
                    className="inline-flex items-center justify-center gap-2 rounded-lg border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2">
                    {obj.label}
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                         stroke="currentColor" className="h-4 w-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15"/>
                      </svg>
                  </span>
                </button>
              )
            })
          }
        </div>
      </>
    );
  }
};