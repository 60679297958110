// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import '../shared/common';

import BlogPosts from './../bundles/order_funnel/BlogPosts';
window['BlogPosts'] = BlogPosts;

import "@hotwired/turbo-rails";
import "react_ujs";

import "../app_order/controllers";

import FileUploadStep from './../bundles/order_funnel/FileUploadStep';
window['FileUploadStep'] = FileUploadStep;

import TextUploadStep from './../bundles/order_funnel/TextUploadStep';
window['TextUploadStep'] = TextUploadStep;

import LanguagePairSelectStep from './../bundles/order_funnel/LanguagePairSelectStep';
window['LanguagePairSelectStep'] = LanguagePairSelectStep;

import ContentTypeSelectInput from './../bundles/order_funnel/ContentTypeSelectInput';
window['ContentTypeSelectInput'] = ContentTypeSelectInput;

import OrderNoteStep from './../bundles/order_funnel/OrderNoteStep';
window['OrderNoteStep'] = OrderNoteStep;

import PackageAndServicesStep from './../bundles/order_funnel/PackageAndServicesStep';
window['PackageAndServicesStep'] = PackageAndServicesStep;

import PaymentStep from './../bundles/order_funnel/PaymentStep';
window['PaymentStep'] = PaymentStep;

import OrderPayment from './../bundles/order_funnel/OrderPayment';
window['OrderPayment'] = OrderPayment;

// console.log("hello from application_order.js");
