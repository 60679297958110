import React, { useState } from "react";

import FormGroupError from "./../../partials/FormGroupError";

export const CouponCode = ({
  ctx,
  form,
  processing,
  errors,
  assignFormState,
}) => {
  const [showCouponCodeEdit, setShowCouponCodeEdit] = useState(false);

  const onKeyDown = (event) => {
    if (event.which === 13 /* Enter */) {
      ctx.handleCouponCodeApply();
    }
  };

  if (form.coupon_code) {
    return (
      <div className="mb-4">
        <div className="flex items-stretch justify-between gap-2">
          <div className="flex items-center justify-end w-full text-custom-dark-black text-sm font-semibold underline">
            {form.coupon_code}
          </div>

          <button
            type="button"
            disabled={processing}
            onClick={ctx.handleCouponCodeRemove}
            className="bg-custom-dark-black/5 focus:ring-primary/80 flex items-center justify-center rounded-lg px-1 py-1 text-sm font-sm text-custom-dark-black hover:bg-custom-dark-black/10 focus:outline-none focus:ring-2 focus:ring-offset-2"
          >
            <svg
              aria-hidden="true"
              className="h-3.5 w-3.5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="mb-4">
      {showCouponCodeEdit && (
        <>
          <FormGroupError error={errors.temporary_coupon_code}>
            <div className="flex flex-col gap-2">
              <input
                type="text"
                name="temporary_coupon_code"
                value={form.temporary_coupon_code}
                placeholder={I18n.payment.enter_coupon_code_here}
                onKeyDown={onKeyDown}
                onChange={(e) =>
                  assignFormState("temporary_coupon_code", e.target.value)
                }
                className="focus:ring-primary block w-full rounded-lg border border-gray-300 bg-white px-4 py-3 text-sm text-gray-700 focus:border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2"
              />
            </div>
          </FormGroupError>
          <div className="grid grid-cols-2 items-stretch gap-3 mt-4">
            <button
              type="button"
              disabled={processing}
              onClick={ctx.handleCouponCodeApply}
              className="bg-primary focus:ring-primary/80 flex items-center justify-center rounded-lg p-3 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              {I18n.payment.apply}
            </button>
            <button
              type="button"
              onClick={(e) => setShowCouponCodeEdit(false)}
              className="bg-custom-dark-black/5 focus:ring-primary/80 flex items-center justify-center rounded-lg p-3 text-sm font-medium text-custom-dark-black hover:bg-custom-dark-black/10 focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              Cancel
            </button>
          </div>
        </>
      )}
      {!showCouponCodeEdit && (
        <button
          id="couponTrigger"
          onClick={(e) => setShowCouponCodeEdit(true)}
          type="button"
          className="flex w-full justify-end text-sm font-medium text-[#01001a] underline"
        >
          {I18n.payment.add_coupon_code}
        </button>
      )}
    </div>
  );
};

export default CouponCode;
