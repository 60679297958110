import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // console.log("hubspot connected")
    
    const element = document.getElementById('hubspot-messages-iframe-container')
    if(element) {
      // console.log("widget element exists!")
      return;
    }
  
    this.checkIt(1);
  }
  
  checkIt(time) {
    // console.log("check it!", time)
    
    if(time > 5){
      console.log("end of retry!")
      return
    }
    
    if (window.HubSpotConversations) {
      window.HubSpotConversations.resetAndReloadWidget();
    } else {
      if(this.caller) {
        clearTimeout(this.caller);
      }
      this.caller = setTimeout(() => {
        this.checkIt(time + 1)
      }, 300)
    }
  }
  
  disconnect() {
    // console.log("hubspot disconnected")
    clearTimeout(this.caller);
  }
}
