import React from "react";
import PropTypes from "prop-types";
import {
  ADDRESS_REQUIRED_DELIVERY_TYPES,
  ADDRESS_TYPE_DELIVERY,
  ADDRESS_TYPE_INVOICE,
  DOMAIN_HIZLICEVIRI,
} from "../utils/OrderConstants";
import CountriesActions from "../actions/CountriesActions";
import FormGroupError from "../partials/FormGroupError";
import AddressActions from "../actions/AddressActions";
import InputMask from "react-input-mask";
import { PhoneInput } from "react-international-phone";

const INIT_ADDRESS_FORM = {
  id: null,
  address_type: ADDRESS_TYPE_DELIVERY,
  name: "",
  receiver_type: "personal",
  receiver_name: "",
  identity_number: "",
  phone_number: "",
  country_id: "",
  city: "",
  corporate_name: "",
  tax_number: "",
  tax_office: "",
  district: "",
  zip_code: "",
  address: "",
  add_as_another_address: true,
  is_default: true,
};

export default class Addresses extends React.Component {
  static propTypes = {
    tmpOrder: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    countryCode: PropTypes.string.isRequired,
    i18n: PropTypes.object.isRequired,
    assignFormState: PropTypes.func.isRequired,
    invoiceAddresses: PropTypes.array.isRequired,
    deliveryAddresses: PropTypes.array.isRequired,
    domain: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.initialAddressForm = { ...INIT_ADDRESS_FORM }
    this.state = {
      addressFormHidden: true,
      processing: false,
      deliveryAddressRequired: ADDRESS_REQUIRED_DELIVERY_TYPES.includes(
        props.form.delivery_type
      ),
      invoiceAddresses: props.invoiceAddresses,
      deliveryAddresses: props.deliveryAddresses,
      countries: [],
      addressForm: {
        ...this.initialAddressForm,
      },
      addressFormErrors: {
        // name: ['doldurulmali'],
        // receiver_type: ['doldurulmali'],
        // receiver_name: ['doldurulmali'],
        // identity_number: ['doldurulmali'],
        // phone_number: ['doldurulmali'],
        // country_id: ['doldurulmali'],
        // city: ['doldurulmali'],
        // corporate_name: ['doldurulmali'],
        // tax_number: ['doldurulmali'],
        // tax_office: ['doldurulmali'],
        // district: ['doldurulmali'],
        // zip_code: ['doldurulmali'],
        // add_as_another_address: ['doldurulmali'],
        // address: ['doldurulmali'],
      },
      errorMessages: [],
    };
    this.addressFormHiddenSet = this.addressFormHiddenSet.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAddressEdit = this.handleAddressEdit.bind(this);
  }

  componentDidMount() {
    // console.log("Addresses componentDidMount");
    CountriesActions.index((json) => {
      if (json.success) {
        const countries = json.data;
        this.setState({
          countries: countries,
        });
  
        const defaultCountries = countries.filter(c => c.code === this.props.countryCode);
        if(defaultCountries) {
          const defaultCountry = defaultCountries[0];
          this.initialAddressForm.country_id = defaultCountry ? defaultCountry.id : ''
        }
      } else {
        console.log("Countries fetch error..");
      }
    });

    setTimeout(() => {
      const options = {
        placement: "right",
        backdrop: true,
        bodyScrolling: false,
        edge: false,
        edgeOffset: "",
        backdropClasses: "bg-gray-900 bg-opacity-50 fixed inset-0 z-40",
        onHide: () => {
          // console.log('drawer is hidden');
          const $element = document.querySelector("[drawer-backdrop]");
          if ($element) {
            $element.remove();
          }
        },
        // onShow: () => {
        //   console.log('drawer is shown');
        // },
        // onToggle: () => {
        //   console.log('drawer has been toggled');
        // }
      };
      const invoiceElement = document.getElementById("invoice-addresses");
      const deliveryElement = document.getElementById("delivery-addresses");
      if (invoiceElement) {
        this.invoiceAddressDrawer = new Drawer(invoiceElement, options);
      }
      if (deliveryElement) {
        this.deliveryAddressDrawer = new Drawer(deliveryElement, options);
      }
    }, 400);
  }

  addressFormHiddenSet(
    value,
    addressType = ADDRESS_TYPE_INVOICE,
    closeDrawer = false
  ) {
    if (closeDrawer) {
      if (addressType === ADDRESS_TYPE_DELIVERY) {
        this.deliveryAddressDrawer.hide();
      } else if (addressType === ADDRESS_TYPE_INVOICE) {
        this.invoiceAddressDrawer.hide();
      }
    }
    this.setState({
      addressFormHidden: value,
      addressForm: {
        ...this.initialAddressForm,
        address_type: addressType,
      },
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const newAddressForm = { ...this.state.addressForm, [name]: value };
    const addressFormErrors = { ...this.state.addressFormErrors, [name]: null };

    this.setState({
      addressFormErrors,
      addressForm: {
        ...newAddressForm,
      },
      errorMessages: [],
    });
  }

  handleAddressEdit(address) {
    if (address.address_type === ADDRESS_TYPE_DELIVERY) {
      this.deliveryAddressDrawer.hide();
    } else if (address.address_type === ADDRESS_TYPE_INVOICE) {
      this.invoiceAddressDrawer.hide();
    }

    this.setState({
      addressFormHidden: false,
      addressForm: {
        ...address,
      },
    });
  }

  handleSubmit(event) {
    const { assignFormState } = this.props;
    const { addressForm } = this.state;
    this.setState({
      processing: true,
    });

    if (addressForm.id === null) {
      AddressActions.create(addressForm, (json) => {
        if (json.success) {
          const address = json.data;
          const key = address.address_type + "Addresses";
          let state = {
            processing: false,
            addressFormHidden: true,
            addressForm: {
              ...this.initialAddressForm,
            },
            [key]: this.state[key].concat([address]),
            addressFormErrors: {},
            errorMessages: [],
          };

          const otherAddress = json.data_second;
          if (otherAddress) {
            const otherKey = otherAddress.address_type + "Addresses";
            state[otherKey] = this.state[otherKey].concat([otherAddress]);
          }

          this.setState(state);
          assignFormState(address.address_type + "_address_id", address.id);
          if (otherAddress) {
            assignFormState(
              otherAddress.address_type + "_address_id",
              otherAddress.id
            );
          }
          PushData.setAddressData(address);
          PushData.customerOrder(this.props.tmpOrder.id, 'Shipping Info Entered');

          // App.removeUserMessageViewCollapse();
        } else {
          let errorMessages = json.error_messages;
          if (json.error) {
            errorMessages.push(json.error);
          }

          this.setState({
            processing: false,
            addressFormErrors: json.errors,
            errorMessages,
          });
        }
      });
    } else {
      AddressActions.update(addressForm, (json) => {
        if (json.success) {
          const address = json.data;
          console.log(address);

          const key = address.address_type + "Addresses";
          const addressList = this.state[key];
          const index = addressList.findIndex((addr) => addr.id === address.id);
          if (index >= 0) {
            Object.assign(addressList[index], address);
            this.setState({
              processing: false,
              addressFormHidden: true,
              addressForm: {
                ...this.initialAddressForm,
              },
              [key]: addressList,
              addressFormErrors: {},
              errorMessages: [],
            });
            assignFormState(address.address_type + "_address_id", address.id);
          }
        } else {
          let errorMessages = json.error_messages;
          if (json.error) {
            errorMessages.push(json.error);
          }

          this.setState({
            processing: false,
            addressFormErrors: json.errors,
            errorMessages,
          });
        }
      });
    }
  }

  render() {
    const { form, assignFormState, i18n, domain, errors, countryCode } = this.props;
    const {
      addressFormHidden,
      deliveryAddressRequired,
      countries,
      invoiceAddresses,
      deliveryAddresses,
      addressForm,
      addressFormErrors,
      processing,
    } = this.state;

    const invoiceAddress = invoiceAddresses.find(
      (add) => add.id === form.invoice_address_id
    );
    const deliveryAddress = deliveryAddresses.find(
      (add) => add.id === form.delivery_address_id
    );
    // console.log(form)

    return (
      <div>
        <div
          id="allAddressList"
          className={`space-y-6 ${addressFormHidden ? "" : "hidden"}`}
        >
          <FormGroupError
            error={errors.delivery_address_id}
            className="space-y-6"
          >
            <button
              type="button"
              id="addNewAddress"
              onClick={(e) =>
                this.addressFormHiddenSet(false, ADDRESS_TYPE_INVOICE)
              }
              className="text-primary flex items-center justify-center gap-2 rounded-lg text-sm font-medium"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-5 w-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              {i18n.addNewAddress}
            </button>

            <div data-label="invoice-addresses">
              {invoiceAddress && (
                <button
                  type="button"
                  onClick={(e) => this.invoiceAddressDrawer.toggle()}
                  data-drawer-target="invoice-addresses"
                  data-drawer-show="invoice-addresses"
                  data-drawer-placement="right"
                  aria-controls="invoice-addresses"
                  className="focus:ring-primary flex items-center justify-center gap-2 rounded-lg border border-gray-200 p-4 text-left text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  <span className="text-custom-dark-black text-sm">
                    {invoiceAddress.address}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </button>
              )}
              <div
                id="invoice-addresses"
                className="fixed right-0 top-0 z-50 h-screen w-full translate-x-full overflow-y-auto bg-white p-4 transition-transform md:w-[500px]"
                tabIndex="-1"
                aria-labelledby="drawer-right-label"
              >
                <div className="mb-2 text-right">
                  <button
                    type="button"
                    onClick={(e) => this.invoiceAddressDrawer.toggle()}
                    data-drawer-hide="invoice-addresses"
                    aria-controls="invoice-addresses"
                    className="inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
                  >
                    <svg
                      aria-hidden="true"
                      className="h-7 w-7"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">{I18n.address.close_menu}</span>
                  </button>
                </div>
                <div>
                  <h2 className="text-custom-dark-black mb-4 text-3xl font-semibold">
                    {I18n.address.my_invoice_addresses}
                  </h2>
                  <div className="space-y-6">
                    {invoiceAddresses.map((address) => {
                      const addressKey = `invoice-address-${address.id}`;

                      return (
                        <div
                          key={address.id}
                          className="relative flex items-center rounded"
                        >
                          <input
                            id={addressKey}
                            type="radio"
                            name="invoice_address_id"
                            checked={address.id === form.invoice_address_id}
                            onChange={() =>
                              assignFormState("invoice_address_id", address.id)
                            }
                            className="text-success-green focus:ring-success-green peer absolute left-5 top-7 h-5 w-5 border-gray-300 bg-gray-100 focus:ring-2 md:top-1/2 md:-translate-y-1/2"
                          />
                          <label
                            htmlFor={addressKey}
                            className="peer-checked:border-success-green peer-checked:ring-success-green/30 flex w-full items-center justify-between gap-4 rounded-lg border border-gray-200 py-5 pl-14 pr-5 peer-checked:ring-4"
                          >
                            <div className="flex flex-col gap-2 sm:flex-row sm:items-center sm:gap-4">
                              <div className="space-y-0.5">
                                <h6 className="text-base font-semibold tracking-wide text-[#01001A]">
                                  {address.name}
                                </h6>
                                <p className="text-sm tracking-wide text-[#656474]">
                                  <strong className="font-semibold">
                                    {address.receiver_name ||
                                      address.corporate_name}
                                  </strong>
                                  {address.phone_number &&
                                    `- ${address.phone_number}`}
                                </p>
                                <p className="text-sm tracking-wide text-[#656474]">
                                  {address.address}
                                </p>
                              </div>
                            </div>
                            <button
                              type="button"
                              onClick={() => this.handleAddressEdit(address)}
                              className="text-sm font-medium text-[#4C4B5E] underline"
                            >
                              {i18n.edit}
                            </button>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            {deliveryAddressRequired && (
              <>
                {/*{ domain.key === DOMAIN_HIZLICEVIRI && deliveryAddresses.length > 0 &&*/}
                {/*    <div>*/}
                {/*      <h6 className="mb-2 text-sm font-medium text-[#01001A]"> Billing information </h6>*/}
                {/*      <div className="flex items-center gap-3">*/}
                {/*        <input id="deliveryAddress" type="checkbox" value="" className="text-primary focus:ring-primary h-4 w-4 rounded border-gray-300 bg-white focus:ring-2"/>*/}
                {/*        <label htmlFor="deliveryAddress" className="text-sm font-medium text-gray-900 dark:text-gray-300">My billing address should be the same as my Delivery address.</label>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*}*/}

                <div data-label="delivery-addresses">
                  {deliveryAddress && (
                    <button
                      type="button"
                      onClick={(e) => this.deliveryAddressDrawer.toggle()}
                      className="text-custom-dark-black flex items-center justify-center gap-2 rounded-lg text-sm font-medium"
                      data-drawer-target="delivery-addresses"
                      data-drawer-show="delivery-addresses"
                      data-drawer-placement="right"
                      aria-controls="delivery-addresses"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="h-5 w-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      {I18n.address.delivery_address}:
                      <span className="text-primary">
                        {deliveryAddress.name}
                      </span>
                    </button>
                  )}

                  {deliveryAddress == null && (
                    <button
                      type="button"
                      id="addNewAddress"
                      onClick={(e) =>
                        this.addressFormHiddenSet(false, ADDRESS_TYPE_DELIVERY)
                      }
                      className="flex items-center justify-center gap-2 rounded-lg text-sm font-medium"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="h-5 w-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      {I18n.address.delivery_address}:
                      <span className="text-primary">{i18n.addNewAddress}</span>
                    </button>
                  )}
                  <div
                    id="delivery-addresses"
                    className="fixed right-0 top-0 z-50 h-screen w-full translate-x-full overflow-y-auto bg-white p-4 transition-transform md:w-[500px]"
                    tabIndex="-1"
                    aria-labelledby="drawer-right-label"
                  >
                    <div className="mb-2 text-right">
                      <button
                        type="button"
                        onClick={(e) => this.deliveryAddressDrawer.toggle()}
                        data-drawer-hide="delivery-addresses"
                        aria-controls="delivery-addresses"
                        className="inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
                      >
                        <svg
                          aria-hidden="true"
                          className="h-7 w-7"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="sr-only">
                          {I18n.address.close_menu}
                        </span>
                      </button>
                    </div>

                    <div>
                      <h2 className="text-custom-dark-black mb-4 text-3xl font-semibold">
                        {I18n.address.my_delivery_addresses}:
                      </h2>
                      <div className="space-y-6">
                        <button
                          type="button"
                          id="addNewAddress"
                          onClick={(e) =>
                            this.addressFormHiddenSet(
                              false,
                              ADDRESS_TYPE_DELIVERY,
                              true
                            )
                          }
                          className="flex w-full items-center justify-center gap-2 rounded-lg bg-[#01001A]/5 px-6 py-3 text-sm font-medium text-[#01001A] focus:outline-none focus:ring-2 focus:ring-[#01001A]/10 focus:ring-offset-2"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="h-5 w-5"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
                              clipRule="evenodd"
                            />
                          </svg>
                          {i18n.addNewAddress}
                        </button>

                        {deliveryAddresses.map((address) => {
                          const addressKey = `address-${address.id}`;

                          return (
                            <div
                              key={address.id}
                              className="relative flex items-center rounded"
                            >
                              <input
                                id={addressKey}
                                type="radio"
                                name="delivery_address_id"
                                checked={
                                  address.id === form.delivery_address_id
                                }
                                onChange={() =>
                                  assignFormState(
                                    "delivery_address_id",
                                    address.id
                                  )
                                }
                                className="text-success-green focus:ring-success-green peer absolute left-5 top-7 h-5 w-5 border-gray-300 bg-gray-100 focus:ring-2 md:top-1/2 md:-translate-y-1/2"
                              />
                              <label
                                htmlFor={addressKey}
                                className="peer-checked:border-success-green peer-checked:ring-success-green/30 flex w-full items-center justify-between gap-4 rounded-lg border border-gray-200 py-5 pl-14 pr-5 peer-checked:ring-4"
                              >
                                <div className="flex flex-col gap-2 sm:flex-row sm:items-center sm:gap-4">
                                  <div className="space-y-0.5">
                                    <h6 className="text-base font-semibold tracking-wide text-[#01001A]">
                                      {address.name}
                                    </h6>
                                    <p className="text-sm tracking-wide text-[#656474]">
                                      <strong className="font-semibold">
                                        {address.receiver_name ||
                                          address.corporate_name}
                                      </strong>
                                      {address.phone_number &&
                                        `- ${address.phone_number}`}
                                    </p>
                                    <p className="text-sm tracking-wide text-[#656474]">
                                      {address.address}
                                    </p>
                                  </div>
                                </div>
                                <button
                                  type="button"
                                  onClick={() =>
                                    this.handleAddressEdit(address)
                                  }
                                  className="text-sm font-medium text-[#4C4B5E] underline"
                                >
                                  {i18n.edit}
                                </button>
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </FormGroupError>
        </div>

        <div
          id="addNewAddressForm"
          className={addressFormHidden ? "hidden" : ""}
        >
          <button
            type="button"
            id="backToAddressList"
            onClick={(e) => this.addressFormHiddenSet(true)}
            className="mb-6 inline-flex items-center justify-center gap-2 text-lg font-medium text-[#01001A]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
            {i18n.back}
          </button>
          <div>
            <div className="grid grid-cols-2 gap-6">
              <div className="col-span-2">
                <FormGroupError
                  error={addressFormErrors && addressFormErrors.name}
                >
                  <input
                    type="text"
                    id="addressName"
                    name="name"
                    value={addressForm.name}
                    onChange={this.handleInputChange}
                    placeholder={I18n.address.address_name}
                    className="focus:ring-primary block w-full rounded-lg border border-gray-300 px-4 py-3.5 text-gray-500 placeholder:text-gray-400 focus:border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2"
                  />
                </FormGroupError>
              </div>

              <div className="col-span-2 gap-8">
                <FormGroupError
                  error={addressFormErrors && addressFormErrors.receiver_type}
                >
                  <div className="flex items-center gap-8">
                    <h6 className="text-sm text-[#01001A]">
                      {I18n.address.receiver_type}
                    </h6>
                    <div className="flex items-center">
                      <input
                        id="personalReceiver"
                        type="radio"
                        value="personal"
                        name="receiver_type"
                        defaultChecked={
                          addressForm.receiver_type === "personal"
                        }
                        onChange={this.handleInputChange}
                        className="text-primary focus:ring-primary h-4 w-4 border-gray-300 bg-white focus:ring-2"
                      />
                      <label
                        htmlFor="personalReceiver"
                        className="ml-2 text-sm font-medium text-[#01001A]"
                      >
                        {I18n.address.receiver_type_personal}
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="corporateReceiver"
                        type="radio"
                        value="corporate"
                        name="receiver_type"
                        defaultChecked={
                          addressForm.receiver_type === "corporate"
                        }
                        onChange={this.handleInputChange}
                        className="text-primary focus:ring-primary h-4 w-4 border-gray-300 bg-white focus:ring-2"
                      />
                      <label
                        htmlFor="corporateReceiver"
                        className="ml-2 text-sm font-medium text-[#01001A]"
                      >
                        {I18n.address.receiver_type_corporate}
                      </label>
                    </div>
                  </div>
                </FormGroupError>
              </div>

              {addressForm.receiver_type === "personal" && (
                <>
                  <div className="col-span-2 sm:col-span-1">
                    <FormGroupError
                      error={
                        addressFormErrors && addressFormErrors.receiver_name
                      }
                    >
                      <input
                        type="text"
                        name="receiver_name"
                        value={addressForm.receiver_name}
                        onChange={this.handleInputChange}
                        className="focus:ring-primary block w-full rounded-lg border border-gray-300 px-4 py-3.5 text-gray-500 placeholder:text-gray-400 focus:border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2"
                        placeholder={I18n.address.receiver_name}
                      />
                    </FormGroupError>
                  </div>

                  {domain.key === DOMAIN_HIZLICEVIRI && (
                    <div className="col-span-2 sm:col-span-1">
                      <FormGroupError
                        error={
                          addressFormErrors && addressFormErrors.identity_number
                        }
                      >
                        <InputMask
                          type="text"
                          name="identity_number"
                          value={addressForm.identity_number}
                          onChange={this.handleInputChange}
                          className="focus:ring-primary block w-full rounded-lg border border-gray-300 px-4 py-3.5 text-gray-500 placeholder:text-gray-400 focus:border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2"
                          mask="99999999999"
                          placeholder={I18n.address.identity_number}
                        />
                      </FormGroupError>
                    </div>
                  )}
                </>
              )}
  
              {addressForm.receiver_type == "corporate" && (
                <>
                <div className="col-span-2 sm:col-span-1">
                  <FormGroupError
                    error={
                      addressFormErrors && addressFormErrors.corporate_name
                    }
                  >
                    <input
                      type="text"
                      name="corporate_name"
                      value={addressForm.corporate_name}
                      onChange={this.handleInputChange}
                      className="focus:ring-primary block w-full rounded-lg border border-gray-300 px-4 py-3.5 text-gray-500 placeholder:text-gray-400 focus:border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2"
                      placeholder={I18n.address.corporate_name}
                    />
                  </FormGroupError>
                </div>
                </>
              )}
              
              <div className="col-span-2 sm:col-span-1">
                <FormGroupError
                  error={addressFormErrors && addressFormErrors.phone_number}
                >
                  <PhoneInput
                    type="text"
                    name="phone_number"
                    defaultCountry={countryCode.toLowerCase()}
                    value={addressForm.phone_number}
                    onChange={(val) => this.handleInputChange({target: {name: "phone_number", type: "text", value: val}})}
                  />
                  {/* <InputMask
                    className="focus:ring-primary block w-full rounded-lg border border-gray-300 px-4 py-3.5 text-gray-500 placeholder:text-gray-400 focus:border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2"
                    mask="0(999) 999 99 99"
                    placeholder={I18n.address.phone_number}
                  /> */}
                </FormGroupError>
              </div>

              <div className="col-span-2 sm:col-span-1">
                <FormGroupError error={addressFormErrors && addressFormErrors.country_id}>
                  <select
                    className="focus:border-primary focus:ring-primary block w-full rounded-lg border border-gray-300 bg-white px-4 py-3.5 text-base text-gray-500"
                    name="country_id"
                    value={addressForm.country_id}
                    onChange={this.handleInputChange}>
                    <option value="">{I18n.address.country}</option>
                    {countries.map((country, index) => {
                      return (
                        <option key={index} value={country.id}>
                          {country.name}
                        </option>
                      );
                    })}
                  </select>
                </FormGroupError>
              </div>

              <div className="col-span-2 sm:col-span-1">
                <FormGroupError error={addressFormErrors && addressFormErrors.city}>
                  <input
                    type="text"
                    name="city"
                    value={addressForm.city}
                    onChange={this.handleInputChange}
                    className="focus:ring-primary block w-full rounded-lg border border-gray-300 px-4 py-3.5 text-gray-500 placeholder:text-gray-400 focus:border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2"
                    placeholder={I18n.address.city}
                  />
                </FormGroupError>
              </div>

              {addressForm.receiver_type == "corporate" && (
                <>
                  <div className="col-span-2 sm:col-span-1">
                    <FormGroupError
                      error={addressFormErrors && addressFormErrors.tax_number}
                    >
                      <InputMask
                        type="text"
                        name="tax_number"
                        value={addressForm.tax_number}
                        onChange={this.handleInputChange}
                        className="focus:ring-primary block w-full rounded-lg border border-gray-300 px-4 py-3.5 text-gray-500 placeholder:text-gray-400 focus:border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2"
                        mask="99999999999"
                        placeholder={I18n.address.tax_number}
                      />
                    </FormGroupError>
                  </div>

                  {domain.key === DOMAIN_HIZLICEVIRI && (
                    <div className="col-span-2 sm:col-span-1">
                      <FormGroupError
                        error={
                          addressFormErrors && addressFormErrors.tax_office
                        }
                      >
                        <input
                          type="text"
                          name="tax_office"
                          value={addressForm.tax_office}
                          onChange={this.handleInputChange}
                          className="focus:ring-primary block w-full rounded-lg border border-gray-300 px-4 py-3.5 text-gray-500 placeholder:text-gray-400 focus:border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2"
                          onKeyPress={(event) => {
                            if (!/[a-z]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          placeholder={I18n.address.tax_office}
                        />
                      </FormGroupError>
                    </div>
                  )}
                </>
              )}

              <div className="col-span-2 sm:col-span-1">
                <FormGroupError
                  error={addressFormErrors && addressFormErrors.district}
                >
                  <input
                    type="text"
                    name="district"
                    value={addressForm.district}
                    onChange={this.handleInputChange}
                    className="focus:ring-primary block w-full rounded-lg border border-gray-300 px-4 py-3.5 text-gray-500 placeholder:text-gray-400 focus:border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2"
                    placeholder={I18n.address.district}
                  />
                </FormGroupError>
              </div>

              <div className="col-span-2 sm:col-span-1">
                <FormGroupError
                  error={addressFormErrors && addressFormErrors.zip_code}
                >
                  <InputMask
                    type="text"
                    name="zip_code"
                    value={addressForm.zip_code}
                    onChange={this.handleInputChange}
                    className="focus:ring-primary block w-full rounded-lg border border-gray-300 px-4 py-3.5 text-gray-500 placeholder:text-gray-400 focus:border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2"
                    mask="99999"
                    placeholder={I18n.address.zip_code}
                  />
                </FormGroupError>
              </div>

              <div className="col-span-2">
                <FormGroupError
                  error={addressFormErrors && addressFormErrors.address}
                >
                  <textarea
                    name="address"
                    value={addressForm.address}
                    onChange={this.handleInputChange}
                    className="focus:ring-primary block w-full rounded-lg border border-gray-300 px-4 py-3.5 text-gray-500 placeholder:text-gray-400 focus:border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2"
                    placeholder={I18n.address.address}
                  ></textarea>
                </FormGroupError>
              </div>

              {addressForm.id === null && (
                <div className="col-span-2 flex flex-col sm:flex-row sm:items-center gap-6">
                  {domain.key === DOMAIN_HIZLICEVIRI &&
                    <div className="flex items-center">
                      <input
                        id="addAsAnotherAddress"
                        type="checkbox"
                        value="yes"
                        name="add_as_another_address"
                        defaultChecked={addressForm.add_as_another_address}
                        onChange={this.handleInputChange}
                        className="text-primary focus:ring-primary h-5 w-5 rounded border-gray-300 bg-white focus:ring-2"
                      />
                      <label
                        htmlFor="addAsAnotherAddress"
                        className="ml-2 text-sm text-gray-700"
                      >
                        {
                          I18n.address[
                          "add_as_another_address_" + addressForm.address_type
                            ]
                        }
                      </label>
                    </div>
                  }
                  <div className="flex items-center">
                    <input
                      id="defaultAddress"
                      type="checkbox"
                      value="yes"
                      name="is_default"
                      defaultChecked={addressForm.is_default}
                      onChange={this.handleInputChange}
                      className="text-primary focus:ring-primary h-5 w-5 rounded border-gray-300 bg-white focus:ring-2"
                    />
                    <label
                      htmlFor="defaultAddress"
                      className="ml-2 text-sm text-gray-700"
                    >
                      {I18n.address.save_as_default}
                    </label>
                  </div>
                </div>
              )}
              <div className="col-span-2">
                <button
                  type="button"
                  disabled={processing}
                  onClick={this.handleSubmit}
                  className="bg-primary focus:ring-primary/80 flex w-full items-center justify-center gap-2 rounded-lg px-6 py-3 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  {I18n.address.save}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
