import PropTypes from "prop-types";
import React from "react";

export default class UploadedFileList extends React.Component {
  static propTypes = {
    files: PropTypes.array.isRequired,
    processing: PropTypes.bool.isRequired,
    filesFailed: PropTypes.array,
    removeFile: PropTypes.func.isRequired,
  };
  static defaultProps = {
    filesFailed: [],
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { files, removeFile, processing } = this.props;
    return (
      <>
        <ul className="space-y-6">
          {files.map((file, index) => {
            return (
              <li
                key={index}
                className="flex items-center justify-between gap-4"
              >
                <div className="flex items-center gap-4">
                  <img
                    src="/images_v2/icons/scanned.svg"
                    className="h-10 w-10"
                  />
                  <span className="truncate text-sm font-semibold text-custom-black">
                    {file.file_name}
                  </span>
                </div>
                <button
                  disabled={processing}
                  onClick={() => removeFile(index)}
                  type="button"
                  className="focus:ring-primary inline-flex h-10 w-10 items-center justify-center rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    ></path>
                  </svg>
                </button>
              </li>
            );
          })}
        </ul>
      </>
    );
  }
}
