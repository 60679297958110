import { Controller } from "@hotwired/stimulus";
import { Accordion } from "flowbite";

// https://github.com/themesberg/flowbite/blob/main/src/components/accordion/index.ts

const Default = {
  alwaysOpen: false,
  activeClasses: 'bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white',
  inactiveClasses: 'text-gray-500 dark:text-gray-400',
  onOpen: () => {},
  onClose: () => {},
  onToggle: () => {},
};

export default class extends Controller {
  connect() {
    const $accordionEl = this.element;
  
    const alwaysOpen = $accordionEl.getAttribute('data-accordion-state');
    const activeClasses = $accordionEl.getAttribute('data-active-classes');
    const inactiveClasses = $accordionEl.getAttribute('data-inactive-classes');
  
    const items = [];
    $accordionEl.querySelectorAll('[data-accordion-target]').forEach(($triggerEl) => {
      // Consider only items that directly belong to $accordionEl
      // (to make nested accordions work).
      if ($triggerEl.closest('[data-accordion-state]') === $accordionEl) {
        const item = {
          id: $triggerEl.getAttribute('data-accordion-target'),
          triggerEl: $triggerEl,
          targetEl: document.querySelector(
            $triggerEl.getAttribute('data-accordion-target')
          ),
          iconEl: $triggerEl.querySelector(
            '[data-accordion-icon]'
          ),
          active: $triggerEl.getAttribute('aria-expanded') === 'true'
        };
        items.push(item);
      }
    });
    
    new Accordion(items, {
      alwaysOpen: alwaysOpen === 'open',
      activeClasses: activeClasses ? activeClasses : Default.activeClasses,
      inactiveClasses: inactiveClasses ? inactiveClasses : Default.inactiveClasses,
    });
  }
}
