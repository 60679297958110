import PropTypes from 'prop-types';
import React from 'react';
import {
  PAYMENT_TYPE_PAYMENT_WAY,
  PAYMENT_TYPE_IYZICO,
  PAYMENT_TYPE_STRIPE,
  PAYMENT_TYPE_TRANSFER
} from "../utils/OrderConstants";
import CreditCardForm from "./payment/CreditCardForm";
import TransferForm from "./payment/TransferForm";
import PaymentWayForm from "./payment/PaymentWayForm";
import StripeForm from "./payment/StripeForm";

export default class PaymentGateWayList extends React.Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    setForm: PropTypes.func.isRequired,
    assignFormState: PropTypes.func.isRequired,
    customerPaymentWays: PropTypes.array.isRequired,
    paymentGateways: PropTypes.array.isRequired,
    errors: PropTypes.object.isRequired,
    ctx: PropTypes.object.isRequired,
    i18n: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
  };
  
  constructor(props) {
    super(props);
    this.setPaymentMethod = this.setPaymentMethod.bind(this);
  }
  
  setPaymentMethod(paymentTypeChoose, extra = {}) {
    const { setForm, form } = this.props;
    if(paymentTypeChoose !== PAYMENT_TYPE_PAYMENT_WAY) {
      extra['customer_payment_way_id'] = null;
    }
  
    if(paymentTypeChoose !== PAYMENT_TYPE_TRANSFER) {
      extra['payment_type_transfer_value'] = null;
    }
    setForm({
      ...form,
      ...extra,
      payment_type_choose: paymentTypeChoose
    });
  }
  
  componentDidMount() {
    // console.log("gateways mounted!")
    const { customerPaymentWays, paymentGateways, setForm, form } = this.props;
    if(form.payment_type_choose) {
      return
    }
    
    if (customerPaymentWays.length > 0) {
      const way = customerPaymentWays.find(obj => obj.is_default);
      if (way) {
        return setForm({
          ...form,
          customer_payment_way_id: way.id,
          payment_type_choose: PAYMENT_TYPE_PAYMENT_WAY
        });
      }
      return setForm({
        ...form,
        payment_type_choose: PAYMENT_TYPE_PAYMENT_WAY
      });
    }
    paymentGateways.map((gateway, index) => {
      if (index === 0) {
        setForm({
          ...form,
          payment_type_choose: gateway.payment_type
        });
      }
    })
  }
  
  render() {
    const { customerPaymentWays, paymentGateways, form, assignFormState, errors } = this.props;
    const paymentGatewayIsExist = (paymentGateways.length !== 0);
    
    return (
      <>
        {!paymentGatewayIsExist &&
          <div className="text-red">{I18n.payment.gateway_not_found}</div>
        }
        {paymentGatewayIsExist &&
          <div className="space-y-6"
               data-inactive-classes="text-custom-gg"
               data-active-classes="bg-white text-[#656474] border-b-0 rounded-bl-none rounded-br-none"
               data-controller="accordion-multi"
               data-accordion-state="collapse">
            {customerPaymentWays.length > 0 &&
              <PaymentWayForm
                customerPaymentWays={customerPaymentWays}
                form={form}
                errors={errors}
                assignFormState={assignFormState}
                setPaymentMethod={this.setPaymentMethod}
              />
            }
  
            {paymentGateways.map((gateway, index) => {
              return (
                <div key={index}>
                  {gateway.payment_type == PAYMENT_TYPE_IYZICO &&
                    <CreditCardForm gateway={gateway}
                                    form={form}
                                    assignFormState={assignFormState}
                                    errors={errors}
                                    locale={this.props.locale}
                                    setPaymentMethod={this.setPaymentMethod}
                                    ctx={this.props.ctx} />
                  }
                  {gateway.payment_type == PAYMENT_TYPE_TRANSFER &&
                    <TransferForm gateway={gateway} form={form} errors={errors} setPaymentMethod={this.setPaymentMethod}/>
                  }
                  {gateway.payment_type == PAYMENT_TYPE_STRIPE &&
                    <StripeForm gateway={gateway}
                                form={form}
                                assignFormState={assignFormState}
                                errors={errors}
                                locale={this.props.locale}
                                setPaymentMethod={this.setPaymentMethod}
                                ctx={this.props.ctx} />
                  }
                </div>
              )}
            )}
          </div>
        }
      </>
    )
  };
}