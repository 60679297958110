import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // this.totalSeconds = this.element.dataset.remainingSeconds;
  
    PushData.pushTrack('analyzing', {
      browser_time: Math.floor((new Date()).getTime() / 1000)
    });
    
    this.subscription = App.cable.subscriptions.create({
        channel: "TmpOrderAnalyzeChannel",
        order_uuid: this.element.dataset.orderUuid,
        uuid: this.element.dataset.orderUuid,
        tmp_order_id: this.element.dataset.tmpOrderId
      }, {
        connected: () => {
          // console.log('connected');
        },
        received: (payload) => {
          // console.log('received', payload);
          window.onbeforeunload = null;
          App.cable.subscriptions.remove(this.subscription);
          this.subscription = null;
          if(payload.success) {
            const tmp_order = payload.tmp_order;
            PushData.pushTrack('analyze_completed', {
              analyze_time: tmp_order.analyze_end_at - tmp_order.analyze_start_at,
              analyze_start_at: tmp_order.analyze_start_at,
              analyze_end_at: tmp_order.analyze_end_at,
              browser_time: Math.floor((new Date()).getTime() / 1000)
            });
            
            const path = this.element.dataset.nextUrl;
            // window.location = path;
            Turbo.visit(path, { action: 'replace' })
          } else {
            PushData.pushTrack('analyze_failed')
            // alert(payload.error_messages[0])
            document.getElementById('countdown-content').classList.add("hidden");
            const errorElement = document.getElementById('error-message');
            errorElement.classList.remove('hidden');
            errorElement.lastElementChild.textContent = payload.error_messages[0];
            document.getElementById('action-button-next').disabled = true;
            document.getElementById('bottom_actions').classList.remove('hidden');
          }
        }
      }
    );
    
    window.onbeforeunload = (e) => {
      // Page is about to refresh! do something...
      return true;
    }
  
    
    const sessionMethod = this.element.dataset.sessionMethod;
    if(sessionMethod == "signed_in"){
      console.log("send login")
      PushData.sendSessionData("login")
    } else if(sessionMethod == "signed_up"){
      console.log("send sign_up")
      PushData.sendSessionData("sign_up")
    } else {
      console.log("no need to send")
    }
    //
    // let call = () => {
    //   return PushData.sendSessionData(sessionMethod)
    // }
    //
    // function checkAndCall(){
    //   if(call()) {
    //     console.log("session method: ", sessionMethod);
    //   } else {
    //     setTimeout(() => {
    //       checkAndCall()
    //     }, 300)
    //   }
    // }
    //
    // checkAndCall();
  }
  
  disconnect() {
    // console.log("disconnected");
    if(this.subscription) {
      App.cable.subscriptions.remove(this.subscription);
    }
  }
}
