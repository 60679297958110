import PropTypes from 'prop-types';
import React from 'react';

// Components
import FormGroupError from './../../partials/FormGroupError';
import {PAYMENT_TYPE_STRIPE} from "../../utils/OrderConstants";

let stripeClientKey = "pk_live_51OZbwCHMsysPLBV7E6PaUpjTZwgqXPwNLz92qHUx9zIRom56DcgnggEwQHgKDarh3BJb5osGfYbSisI1Kly5d3xP00y2EeCOPH";
try {
  if(window.ENV_DEV) {
    console.log("using none production env variable for stripe")
    stripeClientKey = "pk_test_51OZbwCHMsysPLBV7jua1bap088gctmPCDC5kuKpkyzBEuWM4MTltbBKd4Us9Drrp8ffD3yUgqGI4Bxus5dsHQNmY00OB9OytUx";
  }
} catch (err) {
  console.warn("STRIPE_CLIENT_KEY not found:", err)
}

let elements;

// Fetches a payment intent and captures the client secret
async function initializePaymentForm(stripe, ctx, startProcessing = true) {
  if(startProcessing){
    ctx.processing("stripe_form");
  }
  
  const response = await ctx.paymentStartRequest(ctx.state.form, PAYMENT_TYPE_STRIPE);
  if (!response.ok) {
    ctx.processing(null, {}, [I18n.payment.error_unexpected]);
    return
  }
  
  const { client_secret } = await response.json();
  
  const appearance = {
    theme: 'stripe'
  };
  elements = stripe.elements({ appearance, clientSecret: client_secret });
  const paymentElement = elements.create("payment");
  paymentElement.mount("#payment-element");
  
  if(startProcessing){
    ctx.processing();
  }
}

async function handlePaymentFormSubmit(stripe, ctx) {
  ctx.processing("stripe_form");
  const returnUrl = ctx.paymentReturnUrl();
  const { error } = await stripe.confirmPayment({
    elements,
    confirmParams: {
      // Make sure to change this to your payment completion page
      return_url: returnUrl,
    },
  });
  
  if (error.type === "card_error" || error.type === "validation_error") {
    ctx.processing(null, {}, [error.message]);
  } else {
    ctx.processing(null, {}, [I18n.payment.error_unexpected]);
  }
}

// Fetches the payment intent status after payment submission
async function checkPaymentStatus(stripe, ctx) {
  if (!ctx.stripeClientSecret) {
    return;
  }
  
  ctx.processing("stripe_form");
  const stripeClientSecret = ctx.stripeClientSecret;
  const { paymentIntent } = await stripe.retrievePaymentIntent(stripeClientSecret);
  ctx.stripeClientSecret = null;
  
  switch (paymentIntent.status) {
    case "succeeded":
      // Call api service and create order
      const response = await ctx.paymentCallbackRequest(ctx.state.form, paymentIntent, PAYMENT_TYPE_STRIPE);
      const json = await response.json();
      const { success, error } = json;
      if (success) {
        ctx.paymentCompleted(json);
        break;
      }
      
      ctx.processing(null, {}, [error]);
      break;
    case "processing":
      ctx.processing(null, {}, [I18n.payment.error_processing]);
      break;
    case "requires_payment_method":
      ctx.processing(null, {}, [I18n.payment.error_try_again]);
      break;
    default:
      ctx.processing(null, {}, [I18n.payment.error_occurred]);
      break;
  }
}

export default class StripeForm extends React.Component {
  static propTypes = {
    gateway: PropTypes.object.isRequired,
    ctx: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    setPaymentMethod: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    assignFormState: PropTypes.func.isRequired,
  };
  
  // // Payment related functions
  // this.stripeClientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
  // this.clientPaymentHandle = null;
  // this.paymentAmountChanged = null;
  // this.paymentReturnUrl = () => `${window.location.href}`;
  // this.paymentStartRequest = (form, type) => TmpOrderActions.start_payment(form, type);
  // this.paymentCallbackRequest = (form, intent, type) => TmpOrderActions.payment_callback(form, intent, type);
  // this.paymentCompleted = (json) => Turbo.visit(json.data.path, { action: 'replace' });
  
  constructor(props) {
    super(props);
    this.stripe = Stripe(stripeClientKey, { locale: props.locale });
    
    elements = null
    
    initializePaymentForm(this.stripe, props.ctx, !this.props.ctx.stripeClientSecret);
    props.ctx.clientPaymentHandle = (_json, _method) => {
      handlePaymentFormSubmit(this.stripe, props.ctx)
    }
    props.ctx.paymentAmountChanged = () => {
      // TODO: improve the ux we can update payment intent in here
      initializePaymentForm(this.stripe, props.ctx);
    }
  }

  componentDidMount() {
    const { ctx } = this.props;
    checkPaymentStatus(this.stripe, ctx);
  }

  render() {
    const { gateway, form, errors, assignFormState, setPaymentMethod } = this.props;
    
    return (
      <div>
        <button type="button"
                id="payment-heading-stripe"
                className="flex w-full items-center justify-between rounded-lg border border-gray-200 p-5 text-left"
                data-accordion-target="#payment-body-stripe"
                aria-expanded={form.payment_type_choose === gateway.payment_type ? "true" : "false"}
                onClick={e => setPaymentMethod(e.target.closest('button').getAttribute('aria-expanded') === 'true' ? gateway.payment_type : null)}
                aria-controls="payment-body-stripe">
          <div className="flex items-center gap-4">
            <img src="/images_v2/payment/credit-card.svg" alt=""/>
            <div className="text-left">
              <h6 className="text-lg font-medium text-[#01001A]"> { I18n.payment.payment_stripe } </h6>
              <p className="text-sm text-[#4C4B5E]"> { I18n.payment.savedCardsInfo } </p>
            </div>
          </div>
          <svg data-accordion-icon className="h-6 w-6 shrink-0 rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
          </svg>
        </button>
        <div id="payment-body-stripe" className="hidden" aria-labelledby="payment-heading-stripe">
          <div className="border border-t-0 border-gray-200 p-5">
            <div className="space-y-4">
               <div className="special-payment">
                 <div className="form-box">
                   <div id="payment-element">
                   </div>
                   <div id="payment-message" className="hidden"></div>
                 </div>
               </div>
              <FormGroupError error={errors.card_save}>
                <div className="flex items-center">
                  <input type="checkbox"
                         id="card_save"
                         name="card_save"
                         checked={ form.card_save }
                         onChange={(e => assignFormState("card_save", e.target.checked))}
                         className="text-primary focus:ring-primary h-4 w-4 rounded border-gray-300 bg-white focus:ring-2"/>
                  <label htmlFor="card_save" className="cursor-pointer ml-2 text-sm text-gray-700">
                    { I18n.payment.approve_credit_card_save }
                  </label>
                </div>
              </FormGroupError>
            </div>
          </div>
        </div>
      </div>
    )
  }
}