import PropTypes from 'prop-types';
import React from 'react';
import {PAYMENT_TYPE_PAYMENT_WAY, PAYMENT_TYPE_IYZICO} from "../../utils/OrderConstants";
import FormGroupError from "../../partials/FormGroupError";

const ICON_EXISTS_BRANDS = ['mastercard', 'visa'];

export default class PaymentWayForm extends React.Component {
  static propTypes = {
    customerPaymentWays: PropTypes.array.isRequired,
    form: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    assignFormState: PropTypes.func.isRequired,
    setPaymentMethod: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { customerPaymentWays, form, setPaymentMethod, assignFormState, errors } = this.props;
    const selectedPaymentWay = customerPaymentWays.find(a => a.id == form.customer_payment_way_id)
    
    return (
      <div>
        <button type="button"
                id="payment-heading-customer"
                className="flex w-full items-center justify-between rounded-lg border border-gray-200 p-5 text-left"
                data-accordion-target="#payment-body-customer"
                aria-expanded={form.payment_type_choose === PAYMENT_TYPE_PAYMENT_WAY ? "true" : "false"}
                onClick={e => setPaymentMethod(e.target.closest('button').getAttribute('aria-expanded') === 'true' ? PAYMENT_TYPE_PAYMENT_WAY : null)}
                aria-controls="payment-body-customer">
          <div className="flex items-center gap-4">
            <img src="/images_v2/payment/credit-card.svg" alt="Credit Card"/>
            <div className="text-left">
              <h6 className="text-lg font-medium text-[#01001A]"> { I18n.payment.payment_saved_cards } </h6>
              <p className="text-sm text-[#4C4B5E]"> { I18n.payment.savedCardsInfo } </p>
            </div>
          </div>
          <svg data-accordion-icon className="h-6 w-6 shrink-0 rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
          </svg>
        </button>
        <div id="payment-body-customer" className="hidden" aria-labelledby="payment-heading-customer">
          <div className="border border-t-0 border-gray-200 p-5">
            <FormGroupError error={errors.customer_payment_way_id}>
              <div id="creditCardFirst" className="space-y-4">
                {customerPaymentWays.map((paymentWay, index) => {
                    const id = `customer_payment_way_${index}`;
                    const brand = paymentWay.card_brand;
                    let icon = "/images_v2/payment/credit-card.svg";
                    if (ICON_EXISTS_BRANDS.includes(brand)) {
                      icon = `/images_v2/payment/${brand}.svg`;
                    }
                    return (
                      <div key={id} className="relative flex items-center rounded">
                        <input id={id}
                               type="radio"
                               value={paymentWay.id}
                               name="customer_payment_way_id"
                               checked={form.customer_payment_way_id == paymentWay.id}
                               onChange={e => setPaymentMethod(PAYMENT_TYPE_PAYMENT_WAY, { "customer_payment_way_id": paymentWay.id })}
                               className="text-success-green focus:ring-success-green peer absolute left-5 top-6 h-5 w-5 border-gray-300 bg-gray-100 focus:ring-2 sm:top-1/2 sm:-translate-y-1/2"/>
                        <label htmlFor={id} className="cursor-pointer peer-checked:border-success-green peer-checked:text-success-green peer-checked:ring-success-green/30 flex w-full flex-col items-start justify-between gap-4 rounded-lg border border-gray-200 py-5 pl-14 pr-5 peer-checked:ring-4 sm:flex-row sm:items-center">
                          <div className="flex flex-col gap-2 sm:flex-row sm:items-center sm:gap-4">
                            <div className="flex h-12 w-12 shrink-0 items-center justify-center rounded-full border border-gray-200">
                              <img src={icon} alt={brand}/>
                            </div>
                            <div>
                              <h6 className="text-xs text-[#01001A]"> {paymentWay.card_holder_name} </h6>
                              <p className="text-sm font-medium text-[#01001A]"> **** **** **** {paymentWay.card_last_digits} </p>
                              <h6 className="text-xs text-[#01001A]"> {brand?.toUpperCase()} </h6>
                            </div>
                          </div>
                          {/*<button type="button" className="text-sm font-medium text-[#4C4B5E] underline"> Delete </button>*/}
                        </label>
                      </div>
                    )
                  }
                )}
                
                {selectedPaymentWay && selectedPaymentWay.payment_way_type === PAYMENT_TYPE_IYZICO &&
                  <FormGroupError error={errors.card_use_threed}>
                    <div className="flex items-center">
                      <input type="checkbox"
                             id="card_use_threed"
                             name="card_use_threed"
                             checked={ form.card_use_threed }
                             onChange={(e => assignFormState("card_use_threed", e.target.checked))}
                             className="text-primary focus:ring-primary h-4 w-4 rounded border-gray-300 bg-white focus:ring-2"/>
                      <label htmlFor="card_use_threed" className="cursor-pointer ml-2 text-sm text-gray-700">
                        { I18n.payment.use_threed_payment }
                      </label>
                    </div>
                  </FormGroupError>
                }
              </div>
            </FormGroupError>
          </div>
        </div>
      </div>
    )
  }
}