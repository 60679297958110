export const parseCheckFloat = amount => {
  const parsedAmount = parseFloat(amount);
  return isNaN(parsedAmount) ? 0 : parsedAmount;
};

export const parseToFixed = amount => {
  return parseCheckFloat(amount).toFixed(2)
};

export const CurrencyUnits = {
  TRY: '₺',
  EUR: '€',
  USD: '$',
  GBP: '£'
};

export const showPrice = (amount, currency) => {
  return `${CurrencyUnits[currency]} ${parseToFixed(amount).replace('.', ',')}`
};

export function getBusinessDayFromHours(hours) {
  const day = parseInt(hours / 8);
  return `${day}-${day + 1}`;
}