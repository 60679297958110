import PropTypes from "prop-types";
import React from "react";

import FormGroupError from "./../../partials/FormGroupError";
import InputMask from "react-input-mask";

export default class CreditCardForm extends React.Component {
  static propTypes = {
    gateway: PropTypes.object.isRequired,
    ctx: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    setPaymentMethod: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    assignFormState: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { gateway, form, errors, assignFormState, setPaymentMethod } =
      this.props;

    return (
      <div>
        <button
          type="button"
          id="payment-heading-card"
          className="flex w-full items-center justify-between rounded-lg border border-gray-200 p-5 text-left"
          data-accordion-target="#payment-body-card"
          aria-expanded={
            form.payment_type_choose === gateway.payment_type ? "true" : "false"
          }
          onClick={(e) =>
            setPaymentMethod(
              e.target.closest("button").getAttribute("aria-expanded") ===
                "true"
                ? gateway.payment_type
                : null
            )
          }
          aria-controls="payment-body-card"
        >
          <div className="flex items-center gap-4">
            <img src="/images_v2/payment/credit-card.svg" alt="" />
            <div className="text-left">
              <h6 className="text-lg font-medium text-[#01001A]">
                {I18n.payment.payment_stripe}
              </h6>
              <p className="text-sm text-[#4C4B5E]">
                {I18n.payment.savedCardsInfo}
              </p>
            </div>
          </div>
          <svg
            data-accordion-icon
            className="h-6 w-6 shrink-0 rotate-180"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <div
          id="payment-body-card"
          className="hidden"
          aria-labelledby="payment-heading-card"
        >
          <div className="border border-t-0 border-gray-200 p-5">
            <div className="space-y-4">
              <div className="grid gap-10 md:grid-cols-[1fr,224px] xl:gap-24">
                <div>
                  <div className="grid grid-cols-2 gap-6">
                    <FormGroupError
                      error={errors.card_number}
                      className="col-span-2"
                    >
                      <label
                        htmlFor="card_number"
                        className="mb-1 block text-sm text-gray-600"
                      >
                        {I18n.payment.card_number}
                      </label>
                      <div className="hidden card-type"></div>
                      <InputMask
                        type="text"
                        id="card_number"
                        name="card_number"
                        className="focus:ring-primary block w-full rounded-lg border border-gray-300 px-4 py-3.5 text-gray-500 placeholder:text-gray-400 focus:border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2"
                        mask="9999 9999 9999 9999"
                        value={form.card_number}
                        onChange={(e) => {
                          assignFormState("card_number", e.target.value);
                        }}
                        placeholder="0000 0000 0000 0000"
                      />
                      <div className="hidden card-valid"></div>
                    </FormGroupError>

                    <FormGroupError
                      error={errors.card_holder_name}
                      className="col-span-2"
                    >
                      <label
                        htmlFor="card_holder_name"
                        className="mb-1 block text-sm text-gray-600"
                      >
                        {I18n.payment.card_holder_name}
                      </label>
                      <input
                        type="text"
                        id="card_holder_name"
                        name="card_holder_name"
                        value={form.card_holder_name}
                        onKeyPress={(event) => {
                          if (!/^[a-zA-Z ]*$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) =>
                          assignFormState("card_holder_name", e.target.value)
                        }
                        placeholder={I18n.payment.card_holder_name}
                        className="focus:ring-primary block w-full rounded-lg border border-gray-300 px-4 py-3.5 text-gray-500 placeholder:text-gray-400 focus:border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2"
                      />
                    </FormGroupError>

                    <FormGroupError
                      error={errors.card_expiration_date}
                      className="col-span-1"
                    >
                      <label
                        htmlFor="card_expiration_date"
                        className="mb-1 block text-sm text-gray-600"
                      >
                        {I18n.payment.card_expiration_date}
                      </label>
                      <InputMask
                        type="text"
                        id="card_expiration_date"
                        name="card_expiration_date"
                        className="focus:ring-primary block w-full rounded-lg border border-gray-300 px-4 py-3.5 text-gray-500 placeholder:text-gray-400 focus:border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2"
                        mask="99/99"
                        value={form.card_expiration_date}
                        onChange={(e) =>
                          assignFormState(
                            "card_expiration_date",
                            e.target.value
                          )
                        }
                        placeholder="MM/YY"
                      />
                    </FormGroupError>

                    <FormGroupError
                      error={errors.card_cvc2}
                      className="col-span-1"
                    >
                      <label
                        htmlFor="card_cvc2"
                        className="mb-1 block text-sm text-gray-600"
                      >
                        CVV
                      </label>
                      <input
                        type="text"
                        id="card_cvc2"
                        name="card_cvc2"
                        value={form.card_cvc2}
                        onChange={(e) =>
                          assignFormState("card_cvc2", e.target.value)
                        }
                        placeholder="XXX"
                        maxLength="4"
                        className="focus:ring-primary block w-full rounded-lg border border-gray-300 px-4 py-3.5 text-gray-500 placeholder:text-gray-400 focus:border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2"
                      />
                    </FormGroupError>

                    <FormGroupError
                      error={errors.card_use_threed}
                      className="col-span-2"
                    >
                      <input
                        type="checkbox"
                        id="card_use_threed"
                        name="card_use_threed"
                        checked={form.card_use_threed}
                        onChange={(e) =>
                          assignFormState("card_use_threed", e.target.checked)
                        }
                        className="text-primary focus:ring-primary h-4 w-4 rounded border-gray-300 bg-white focus:ring-2"
                      />
                      <label
                        htmlFor="card_use_threed"
                        className="cursor-pointer ml-2 text-sm text-gray-700"
                      >
                        {I18n.payment.use_threed_payment}
                      </label>
                    </FormGroupError>

                    <FormGroupError
                      error={errors.card_save}
                      className="col-span-2"
                    >
                      <input
                        type="checkbox"
                        id="card_save"
                        name="card_save"
                        checked={form.card_save}
                        onChange={(e) =>
                          assignFormState("card_save", e.target.checked)
                        }
                        className="text-primary focus:ring-primary h-4 w-4 rounded border-gray-300 bg-white focus:ring-2"
                      />
                      <label
                        htmlFor="card_save"
                        className="cursor-pointer ml-2 text-sm text-gray-700"
                      >
                        {I18n.payment.approve_credit_card_save}
                      </label>
                    </FormGroupError>
                  </div>
                </div>
                <div className="flex flex-col items-start gap-4 sm:flex-row md:flex-col md:gap-16">
                  <div className="flex min-h-[144px] w-full flex-col items-end overflow-hidden rounded-lg bg-white p-6 text-right shadow-xl">
                    <img
                      src="/images_v2/payment/card-chip.svg"
                      className="mb-4"
                      alt=""
                    />
                    <span
                      id="cardNumberArea"
                      className="mb-2.5 inline-block text-base font-semibold tracking-widest text-[#01001A]"
                    >
                      {form.card_number
                        ? `${form.card_number.substring(0, 4)} **** **** ****`
                        : "**** **** **** ****"}
                    </span>
                    <div className="flex w-full items-center justify-between text-xs text-[#7E7E8B]">
                      <span id="cardNameArea">
                        {form.card_holder_name
                          ? form.card_holder_name
                          : "Name Surname"}
                      </span>
                      <span id="cardExpiryDateArea">
                        {form.card_expiration_date
                          ? form.card_expiration_date
                          : "MM/YY"}
                      </span>
                    </div>
                  </div>
                  <div className="flex min-h-[144px] w-full flex-col items-end justify-end overflow-hidden rounded-lg bg-white p-6 text-right shadow-xl">
                    <div className="w-full">
                      <div className="mb-1 flex w-full items-center justify-end text-xs text-[#7E7E8B]">
                        <span id="cardCvvArea">CVV</span>
                      </div>
                      <div className="relative flex h-[24px] w-full items-center justify-end bg-[#01001A0A] text-xs font-semibold text-[#7E7E8B] before:absolute before:left-[-50px] before:top-0 before:h-full before:w-[50px] before:bg-[#01001A0A] after:absolute after:right-[-50px] after:top-0 after:h-full after:w-[50px] after:bg-[#01001A0A]">
                        {form.card_cvc2 ? form.card_cvc2 : "XXX"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
