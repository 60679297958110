import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const mobileDetailsTrigger = document.querySelector("#mobileDetailPrice");
    const mobileDetailsArrow = document.querySelector("#mobileDetailPrice svg");
    const priceDetail = document.querySelector("#priceDetail");

    mobileDetailsTrigger.addEventListener("click", () => {
      priceDetail.classList.toggle("hidden");
      mobileDetailsArrow.classList.toggle("rotate-180");
    });
  }
}
