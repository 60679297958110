import React, { createRef } from "react";
import Dropzone from "react-dropzone";

const OrderFileDropZone = ({ onAcceptedFileDrop, onRejectedFileDrop }) => {
  const inputRef = createRef(null);

  return (
    <Dropzone
      ref={inputRef}
      onDropAccepted={onAcceptedFileDrop}
      onDropRejected={onRejectedFileDrop}
      maxSize={100000000}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps()}
          className="flex w-full sm:w-1/2 max-h-[212px] justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 px-6 py-8 transition-colors hover:border-gray-400"
        >
          <div className="space-y-1 text-center">
            <input {...getInputProps()} />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="mx-auto h-8 w-8 text-slate-400"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
              />
            </svg>
            <div className="flex text-sm text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative flex cursor-pointer flex-col items-center rounded-md font-medium text-gray-700 focus-within:outline-none"
              >
                <span className="mb-4 inline-block">
                  {I18n.fileUploadSection.dragAndDropFiles}
                </span>
                {/*<input id="file-upload" name="file-upload" type="file" className="sr-only"/>*/}
                <span className="bg-primary focus:ring-primary/80 mb-2 inline-flex items-center justify-center rounded-lg px-2 py-3 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2">
                  {I18n.fileUploadSection.chooseFile}
                </span>
                <p className="text-sm text-gray-500">
                  {I18n.fileUploadSection.maximumSize}: 100 MB
                </p>
              </label>
            </div>
          </div>
        </div>
      )}
    </Dropzone>
  );
};
export default OrderFileDropZone;
