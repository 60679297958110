import React from 'react';
import {Modal} from 'flowbite-react';
import TargetLanguagesModalContent from './TargetLanguagesModalContent';

const TargetLanguagesModal = (props) => {
  return (
    <>
      {/*{props.targetLanguages.length > 0 &&*/}
      {/*  <button onClick={() => props.setOpenTargetLanguagesModal('dismissible')}*/}
      {/*          className="text-sm font-medium text-gray-700 underline focus:outline-none" type="button">*/}
      {/*    {I18n.languagePairSelectSection.showMore}*/}
      {/*  </button>*/}
      {/*}*/}

      <Modal dismissible show={props.openTargetLanguagesModal === 'dismissible'}
             onClose={() => props.setOpenTargetLanguagesModal(undefined)}
             size="4xl">
        <TargetLanguagesModalContent {...props} setOpenTargetLanguagesModal={props.setOpenTargetLanguagesModal} />
      </Modal>
    </>
  )
}
export default TargetLanguagesModal
