import request from '../../utils/request'

const deleteData = (file, orderUUID) => {
  return {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'DELETE',
    body: JSON.stringify({
      file_name: file.file_name,
      file_size: file.file_size,
      order_uuid: orderUUID
    })
  };
};

export const UploadActions = {
  upload: (file, orderUUID, call = null) => {
    const data = new FormData();
    data.append('upload[file_virtual]', file);
    data.append('upload[file_name]', file.name);
    data.append('upload[file_size]', file.size);
    data.append('upload[order_uuid]', orderUUID);
    data.append('upload[upload_type]', 'file');
    return request(`/uploads?order_uuid=${orderUUID}`, call, {
      method: 'POST',
      body: data
    });
  },
  uploadNote: (file, orderUUID, call = null) => {
    const data = new FormData();
    data.append('upload[file_virtual]', file);
    data.append('upload[file_name]', file.name);
    data.append('upload[file_size]', file.size);
    data.append('upload[order_uuid]', orderUUID);
    data.append('upload[upload_type]', 'note');
    return request(`/uploads?order_uuid=${orderUUID}`, call, {
      method: 'POST',
      body: data
    });
  },
  uploadText: (object, orderUUID, call=null) => {
    const data = new FormData();
    data.append('upload[text]', object.text);
    data.append('upload[name]', object.name);
    data.append('upload[order_uuid]', orderUUID);
    return request(`/uploads/create_from_text?order_uuid=${orderUUID}`, call, {
      method: 'POST',
      body: data
    });
  },
  delete: (file, orderUUID, call = null) => {
    return request(`/uploads/${file.upload_id}?order_uuid=${orderUUID}`, call, deleteData(file, orderUUID));
  }
};

export default UploadActions;
