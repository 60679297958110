import PropTypes from "prop-types";
import React from "react";
import Dropzone from "react-dropzone";

import UploadFileList from "./components/UploadFileList";
import UploadedFileList from "./components/UploadedFileList";
import UploadingFiles from "./components/UploadingFiles";

// Actions
import UploadActions from "./actions/UploadActions";

export default class FileUploadStep extends React.Component {
  static propTypes = {
    orderUUID: PropTypes.string.isRequired,
    orderNote: PropTypes.string,
    fileUploads: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.orderNoteAdded = false;
    this.state = {
      processing: false,
      uploadedFiles: [
        // { file_name: "aa", file_size: 1000 },
        ...props.fileUploads,
      ],
      uploadingFiles: [
        // {file: {name: "aa", size: 1000}},
        // {file: {name: "aa", size: 1000}},
      ],
      rejectedFiles: [
        // {file_name: "aa", file_size: 1000, message: 'Error'},
        // {file_name: "aa", file_size: 1000, message: 'Error'},
      ],
    };
  }

  fileUploaded = (file, id) => {
    return (json) => {
      if (json.success) {
        this.setState({
          uploadedFiles: this.state.uploadedFiles.concat([
            {
              file_name: file.name,
              file_size: file.size,
              upload_id: json.upload_id,
            },
          ]),
        });
        PushData.pushTrack("reference_file_added");
      } else {
        this.setState({
          rejectedFiles: this.state.rejectedFiles.concat([
            {
              file_name: file.name,
              file_size: file.size,
              message: json.error,
            },
          ]),
        });
        // PushData.pushTrack('reference_file_adding_failed');
      }
      this.setState({
        uploadingFiles: this.state.uploadingFiles.filter((u) => u.id !== id),
      });
    };
  };

  onRejectedFileDrop = (files) => {
    let newFiles = [];
    files.forEach((obj) => {
      const id = Date.now();
      newFiles.push({
        file_name: obj.file.name,
        file_size: obj.file.size,
        message: obj.errors[0].message,
      });
    });
    this.setState({
      rejectedFiles: this.state.rejectedFiles.concat(newFiles),
    });
  };

  onAcceptedFileDrop = (files) => {
    const { orderUUID } = this.props;
    let newFiles = [];
    files.forEach((file) => {
      const id = Date.now();
      newFiles.push({ file: file, id: id });
      UploadActions.uploadNote(file, orderUUID, this.fileUploaded(file, id));
    });
    this.setState({
      uploadingFiles: this.state.uploadingFiles.concat(newFiles),
    });

    // window.onbeforeunload = (e) => {
    //   // Page is about to refresh! do something...
    //   return true;
    // }
  };

  handleRemoveFileResult = (removedFile) => (json) => {
    if (json.success) {
      const uploadedFiles = this.state.uploadedFiles.filter(
        (file) => file.upload_id !== removedFile.upload_id
      );
      this.setState({
        uploadedFiles,
        processing: false,
      });
    } else {
      alert(json.error);
      this.setState({
        processing: false,
      });
    }
  };

  removeFile(index) {
    const { orderUUID } = this.props;

    this.setState({
      processing: true,
    });
    const removedFile = this.state.uploadedFiles[index];
    UploadActions.delete(
      removedFile,
      orderUUID,
      this.handleRemoveFileResult(removedFile)
    );
  }

  removeRejectedFile(index) {
    const rejectedFiles = this.state.rejectedFiles.filter(
      (_, i) => i !== index
    );
    this.setState({
      rejectedFiles,
    });
  }

  orderNoteChanged() {
    if (!this.orderNoteAdded) {
      PushData.pushTrack("brief_added");
      this.orderNoteAdded = true;
    }
  }

  render() {
    const { orderNote } = this.props;
    const { rejectedFiles, uploadingFiles, uploadedFiles, processing } =
      this.state;

    return (
      <div className="flex flex-col-reverse md:flex-col gap-6">
        <div className="rounded-lg bg-white p-4 shadow-custom md:p-6">
          <h2 className="mb-4 text-lg font-semibold tracking-wide text-custom-black">
            {I18n.contentTypeBriefSection.addBrief}
          </h2>
          <div className="mb-4">
            <textarea
              id="message"
              rows="4"
              onChange={this.orderNoteChanged.bind(this)}
              defaultValue={orderNote}
              name="order[order_note]"
              className="focus:border-primary focus:ring-primary block w-full resize-none rounded-lg border border-custom-gray bg-white p-4 text-base text-gray-900 placeholder:text-gray-500"
              placeholder={I18n.contentTypeBriefSection.typeTextPlaceholder}
            ></textarea>
          </div>

          <hr className="my-4 border-[#01001A0A]" />

          <Dropzone
            onDropAccepted={this.onAcceptedFileDrop}
            onDropRejected={this.onRejectedFileDrop}
            maxSize={100000000}
          >
            {({ getRootProps, getInputProps }) => (
              <span {...getRootProps()} role="span">
                <input {...getInputProps()} />
                <label
                  htmlFor="attach-file"
                  className="border-primary text-primary hover:bg-primary/5 inline-flex w-full cursor-pointer items-center justify-center gap-1.5 rounded-lg border px-4 py-3 text-sm font-medium sm:w-auto"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13"
                    />
                  </svg>
                  {I18n.contentTypeBriefSection.attachFile}
                </label>
              </span>
            )}
          </Dropzone>
        </div>

        {(uploadingFiles.length > 0 ||
          rejectedFiles.length > 0 ||
          uploadedFiles.length > 0) && (
          <div className="rounded-lg bg-white p-4 shadow-custom md:p-6">
            <h2 className="mb-4 text-lg font-semibold tracking-wide text-custom-black">
              {I18n.contentTypeBriefSection.files}
            </h2>

            {uploadingFiles.length > 0 &&
              <UploadingFiles files={uploadingFiles} i18n={I18n.contentTypeBriefSection}/>
            }

            {rejectedFiles.length > 0 && (
              <div className="mb-4">
                <UploadFileList
                  type="rejected"
                  title={I18n.contentTypeBriefSection.rejectedFiles}
                  files={rejectedFiles}
                  processing={processing}
                  removeFile={this.removeRejectedFile.bind(this)}
                />
              </div>
            )}

            {/*{uploadedFiles.length > 0 &&*/}
            {/*  <UploadFileList*/}
            {/*    type="accepted"*/}
            {/*    title={ I18n.contentTypeBriefSection.completedFiles }*/}
            {/*    files={uploadedFiles}*/}
            {/*    processing={processing}*/}
            {/*    removeFile={this.removeFile.bind(this)}/>*/}
            {/*}*/}

            {uploadedFiles.length > 0 && (
              <div className="mb-4">
                <UploadedFileList
                  files={uploadedFiles}
                  processing={processing}
                  removeFile={this.removeFile.bind(this)}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
