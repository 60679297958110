import React from 'react';
import {stringToHtml} from "../../utils/stringHelper";

const HoursFaster = ({diff}) => {
  if(diff > 8) {
    const day = parseInt(diff / 8);
    const hour = parseInt(diff % 8);
    
    return (
      <span className="text-primary bg-primary/10 inline-flex items-center rounded-[4px] px-2 py-1 text-xs font-medium"
            dangerouslySetInnerHTML={{__html: stringToHtml(I18n.packageSection.daysAndHoursFaster, {day: day, hour: hour})}}>
    </span>
    );
  }
  
  return (
    <span className="text-primary bg-primary/10 inline-flex items-center rounded-[4px] px-2 py-1 text-xs font-medium"
          dangerouslySetInnerHTML={{__html: stringToHtml(I18n.packageSection.hoursFaster, {hour: diff})}}>
    </span>
  );
}
export default HoursFaster