import { Controller } from "@hotwired/stimulus";
import { Tooltip } from "flowbite";

export default class extends Controller {
  connect() {
    const $triggerEl = this.element;
    const id = $triggerEl.dataset.tooltipTarget;
    const $targetEl = document.getElementById(id);
    const options = {
      placement: $triggerEl.dataset.tooltipPlacement,
    };
    
    new Tooltip($targetEl, $triggerEl, options);
  }
}
