import request from '../../utils/request'

export const OrderActions = {
  complete: (url, form, call) => {
    const data = new FormData();
    data.append('order[package_id]', form.package_id);
    data.append('order[delivery_speed]', form.delivery_speed);
    data.append('order[is_certified_translation]', form.is_certified_translation);
    data.append('order[is_notarised]', form.is_notarised);
    data.append('order[delivery_type]', form.delivery_type);
    data.append('order[total_payment_amount]', form.total_payment_amount);
    return request(url, call, {
      method: 'POST',
      body: data
    });
  },
  makePayment: (url, form, call) => {
    const data = new FormData();
    data.append('order[payment_type_choose]', form.payment_type_choose || "");
    data.append('order[invoice_address_id]', form.invoice_address_id || "");
    data.append('order[delivery_address_id]', form.delivery_address_id || "");
    data.append('order[customer_payment_way_id]', form.customer_payment_way_id || "");
    data.append('order[payment_type_transfer_value]', form.payment_type_transfer_value || "");
    data.append('order[invoice_note]', form.invoice_note || "");
    data.append('order[card_save]', form.card_save || "");
    data.append('order[card_number]', form.card_number || "");
    data.append('order[card_holder_name]', form.card_holder_name || "");
    data.append('order[card_expiration_date]', form.card_expiration_date || "");
    data.append('order[card_cvc2]', form.card_cvc2 || "");
    data.append('order[card_use_threed]', form.card_use_threed || "");
    return request(url, call, {
      method: 'POST',
      body: data
    });
  },
};

export default OrderActions;
