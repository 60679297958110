import PropTypes from "prop-types";
import React from "react";

import OrderFileDropZone from "./components/OrderFileDropZone";
import UploadedFileList from "./components/UploadedFileList";
import UploadFileList from "./components/UploadFileList";

// Actions
import UploadActions from "./actions/UploadActions";
import UploadingFiles from "./components/UploadingFiles";

export default class FileUploadStep extends React.Component {
  static propTypes = {
    orderUUID: PropTypes.string.isRequired,
    fileUploads: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      displayLargeFileError: false,
      uploadedFiles: [
        // { file_name: "aa", file_size: 1000 },
        ...props.fileUploads,
      ],
      uploadingFiles: [
        // {file: {name: "aa", size: 1000}},
        // {file: {name: "aa", size: 1000}},
      ],
      rejectedFiles: [
        // {file_name: "aa", file_size: 1000, message: 'Error'},
        // {file_name: "aa", file_size: 1000, message: 'Error'},
      ],
    };
  }

  fileUploaded = (file, id) => {
    return (json) => {
      if (json.success) {
        this.setState({
          uploadedFiles: this.state.uploadedFiles.concat([
            {
              file_name: file.name,
              file_size: file.size,
              upload_id: json.upload_id,
            },
          ]),
        });
        PushData.pushTrack("file_uploaded", {
          file_type: json.file_content_type,
          file_size: file.size,
        });
      } else {
        this.setState({
          rejectedFiles: this.state.rejectedFiles.concat([
            {
              file_name: file.name,
              file_size: file.size,
              message: json.error,
            },
          ]),
        });
        PushData.pushTrack("file_upload_failed", {
          file_type: json.file_content_type,
          file_size: file.size,
          message: json.error,
        });
      }
      this.setState({
        uploadingFiles: this.state.uploadingFiles.filter((u) => u.id !== id),
      });
    };
  };

  onRejectedFileDrop = (files) => {
    let newFiles = [];
    files.forEach((obj) => {
      newFiles.push({
        file_name: obj.file.name,
        file_size: obj.file.size,
        message: obj.errors[0].message,
      });
    });
    this.setState({
      displayLargeFileError: true,
      rejectedFiles: this.state.rejectedFiles.concat(newFiles),
    });
  };

  onAcceptedFileDrop = (files) => {
    const { orderUUID } = this.props;
    let newFiles = [];
    files.forEach((file) => {
      // if(file.type.includes('image')) {
      //   // do something
      // }
      const id = Date.now();
      newFiles.push({ file: file, id: id });
      UploadActions.upload(file, orderUUID, this.fileUploaded(file, id));
    });
    this.setState({
      uploadingFiles: this.state.uploadingFiles.concat(newFiles),
    });

    // window.onbeforeunload = (e) => {
    //   // Page is about to refresh! do something...
    //   return true;
    // }
  };

  handleRemoveFileResult = (removedFile) => (json) => {
    if (json.success) {
      const uploadedFiles = this.state.uploadedFiles.filter(
        (file) => file.upload_id !== removedFile.upload_id
      );
      this.setState({
        uploadedFiles,
        processing: false,
      });
    } else {
      alert(json.error);
      this.setState({
        processing: false,
      });
    }
  };

  removeFile(index) {
    const { orderUUID } = this.props;

    this.setState({
      processing: true,
    });
    const removedFile = this.state.uploadedFiles[index];
    UploadActions.delete(
      removedFile,
      orderUUID,
      this.handleRemoveFileResult(removedFile)
    );
  }

  removeRejectedFile(index) {
    const rejectedFiles = this.state.rejectedFiles.filter(
      (_, i) => i !== index
    );
    this.setState({
      rejectedFiles,
    });
  }

  render() {
    const {
      rejectedFiles,
      uploadingFiles,
      uploadedFiles,
      displayLargeFileError,
      processing,
    } = this.state;

    return (
      <div className="space-y-6">
        <div className="rounded-lg bg-white p-4 shadow-custom md:p-6">
          <div className="mb-4 flex items-center justify-between">
            <h2 className="text-lg font-semibold tracking-wide text-custom-black">
              {I18n.fileUploadSection.uploadFiles}
            </h2>
            <button
              data-modal-toggle="supportedFileFormat"
              data-controller="ModalController"
              className="text-primary inline-flex items-center justify-center rounded-lg border border-[#908BFF] px-3 py-2 text-xs font-medium focus:outline-none focus:ring-2 focus:ring-[#908BFF]/80 focus:ring-offset-2"
              type="button"
            >
              {I18n.fileUploadSection.supportedFileFormats}
            </button>
          </div>
          <div className="flex flex-col-reverse sm:flex-row items-stretch gap-3 md:gap-8">
            {/*<div className="flex max-h-[212px] justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 px-6 py-8 transition-colors hover:border-gray-400 md:col-span-3">*/}

            <OrderFileDropZone
              onAcceptedFileDrop={this.onAcceptedFileDrop}
              onRejectedFileDrop={this.onRejectedFileDrop}
            />

            <div className="w-full sm:w-1/2 max-h-[212px] overflow-y-auto rounded-lg border border-gray-200 p-4">
              {uploadedFiles.length > 0 && (
                <UploadedFileList
                  files={uploadedFiles}
                  processing={processing}
                  removeFile={this.removeFile.bind(this)}
                />
              )}
            </div>
          </div>
        </div>

        {displayLargeFileError && (
          <div className="flex items-center gap-2 rounded-lg border border-custom-orange bg-custom-orange/10 px-3 py-3">
            <span className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-custom-orange text-white">
              <svg
                className="h-4 w-4"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.99935 8V11.3333"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.99868 5.33366C7.90668 5.33366 7.83202 5.40833 7.83268 5.50033C7.83268 5.59233 7.90735 5.66699 7.99935 5.66699C8.09135 5.66699 8.16602 5.59233 8.16602 5.50033C8.16602 5.40833 8.09135 5.33366 7.99868 5.33366"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.6667 14H3.33333C2.59667 14 2 13.4033 2 12.6667V3.33333C2 2.59667 2.59667 2 3.33333 2H12.6667C13.4033 2 14 2.59667 14 3.33333V12.6667C14 13.4033 13.4033 14 12.6667 14Z"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span className="flex items-center text-sm text-[#BD880E]">
              {I18n.fileUploadSection.contactWithSalesForLargeFiles}
            </span>
          </div>
        )}

        {(uploadingFiles.length > 0 || rejectedFiles.length > 0) && (
          <div className="rounded-lg bg-white p-4 shadow-custom md:p-6">
            <h2 className="mb-4 text-lg font-semibold tracking-wide text-custom-black">
              {I18n.fileUploadSection.files}
            </h2>

            {uploadingFiles.length > 0 &&
              <UploadingFiles files={uploadingFiles} i18n={I18n.fileUploadSection}/>
            }

            {rejectedFiles.length > 0 && (
              <UploadFileList
                type="rejected"
                title={I18n.fileUploadSection.rejectedFiles}
                files={rejectedFiles}
                processing={processing}
                removeFile={this.removeRejectedFile.bind(this)}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}
