import PropTypes from 'prop-types';
import React from 'react';
import PrettyBytes from 'react-pretty-bytes';

const TYPE_ACCEPTED = "accepted";
const TYPE_REJECTED = "rejected";

export default class UploadFileList extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    files: PropTypes.array.isRequired,
    processing: PropTypes.bool.isRequired,
    filesFailed: PropTypes.array,
    removeFile: PropTypes.func.isRequired
  };
  static defaultProps = {
    filesFailed: []
  };
  
  constructor(props) {
    super(props);
  }
  
  isRequired() {
    return this.props.type === TYPE_ACCEPTED;
  }
  
  progressClass() {
    return (this.props.type === TYPE_REJECTED) ? "bg-custom-orange" : "bg-success-green";
  }
  
  render() {
    const {files, type, title, removeFile, processing} = this.props;
    return (
      files.map((file, index) => {
        return (
          <div className="rounded-md border border-custom-white px-5 py-3 mt-2" key={index}>
            <div className="flex items-center justify-between">
              <div className="mb-2 text-sm font-semibold text-custom-black">
                {title}
              </div>
              <button disabled={processing} type="button" onClick={() => removeFile(index)}>
                <svg className="h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd"
                        d="M12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21Z"
                        fill="#DBDBDF"/>
                  <path d="M14.8299 9.16992L9.16992 14.8299" stroke="#01001A" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round"/>
                  <path d="M14.8299 9.16992L9.16992 14.8299" stroke="#FBFBFC" strokeOpacity="0.6" strokeWidth="1.5"
                        strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M14.8299 14.8299L9.16992 9.16992" stroke="#01001A" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round"/>
                  <path d="M14.8299 14.8299L9.16992 9.16992" stroke="#FBFBFC" strokeOpacity="0.6" strokeWidth="1.5"
                        strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </button>
            </div>
            <div className="uploaded-file">
              <div className="flex items-center justify-between pr-8 text-xs text-gray-500">
                <span>{file.file_name}</span>
                <span className="flex items-center justify-between">
                  <PrettyBytes bytes={file.file_size}/>
                  <div className="p-1" aria-hidden="true">·</div>
                  <div>{ this.props.type === TYPE_REJECTED ? "%0" : "%100"}</div>
                </span>
              </div>
              <div className="flex items-center gap-2">
                <div className="h-1 w-full rounded-full bg-slate-200">
                  <div className={`${this.progressClass()} h-1 rounded-full`} style={{width: "100%"}}>
                  </div>
                </div>
                {type == TYPE_REJECTED &&
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21Z"
                          fill="#EF300A"/>
                    <path d="M14.8299 9.16992L9.16992 14.8299" stroke="white" strokeWidth="1.5"
                          strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M14.8299 14.8299L9.16992 9.16992" stroke="white" strokeWidth="1.5"
                          strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                }
                {type == TYPE_ACCEPTED &&
                  <svg className="h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21Z"
                          fill="#07D79C"/>
                    <path d="M16 10L11 15L8 12" stroke="white" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                  </svg>
                }
              </div>
              { file.message &&
                <span className="text-xs text-red-600">
                  {file.message }
                </span>
              }
            </div>
          </div>
        );
      })
    );
  }
};