import PropTypes from 'prop-types';
import React from 'react';
import Select, { components } from 'react-select';

function convertToOptions(translationExperts) {
  return translationExperts.map(translationExpert => {
    return {value: translationExpert.id, label: translationExpert.name, icon: translationExpert.icon}
  }).sort((a, b) => a.label.localeCompare(b.label));
}

const CustomOption = ({innerProps, selectProps, data, children}) => {
  const selected = selectProps.value === data;
  return (
    <div className={`${selected ? "react-select-option-selected" : "react-select-option"}`} {...innerProps}>
      <div className="flex items-center gap-2 text-sm font-medium">
        <img src={`/images_v2/translation_experts/${data.icon}`} className={`${selected ? 'text-primary rounded-md' : 'text-primary'} h-8 w-8`}/>
        {children}
      </div>
    </div>
  )
}

const Control = ({ children, selectProps, ...props }) => {
  if(props.hasValue) {
    const value = selectProps.value
    return (
      <components.Control {...props} className="gap-1 text-sm font-medium">
        <img src={`/images_v2/translation_experts/${value.icon}`} className="text-primary h-8 w-8 ml-2"/>
        {children}
      </components.Control>
    );
  }
  
  return (
    <components.Control {...props}>
      {children}
    </components.Control>
  );
}

function getSelectTheme(theme) {
  return {
    ...theme,
    borderWidth: '1px',
    borderRadius: '0.5rem',
    colors: {
      ...theme.colors,
      primary: '#6159ff0d',
    },
  };
}

export default class LanguagePairSelectStep extends React.Component {
  static propTypes = {
    orderUUID: PropTypes.string.isRequired,
    translationExperts: PropTypes.array,
    translationExpertId: PropTypes.string,
  };
  
  constructor(props) {
    super(props);
    this.translationExpertOptions = convertToOptions(props.translationExperts);
    const object = props.translationExperts.filter( a => a.id.toString() === props.translationExpertId)
    this.state = {
      translationExpert: convertToOptions(object)[0]
    };
  }
  
  setTranslationExpert(val) {
    if(val) {
      document.getElementById('action-button-next').disabled = false;
      PushData.pushTrack('content_type_selected', { content_type: val.label });
    } else {
      document.getElementById('action-button-next').disabled = true;
    }
    this.setState({
      translationExpert: val,
    })
  }
  
  render() {
    const {translationExpert} = this.state;

    return (
      <Select
        components={{ Option: CustomOption, Control }}
        className="react-select-container"
        classNamePrefix="react-select"
        theme={theme => getSelectTheme(theme)}
        // menuIsOpen
        value={translationExpert}
        placeholder={I18n.contentTypeBriefSection.pleaseSelect}
        isClearable={true}
        isSearchable={true}
        name="order[translation_expert_id]"
        options={this.translationExpertOptions}
        onChange={this.setTranslationExpert.bind(this)}
      />
    );
  }
}
