import { application } from "./application";


import UploadFilesController from "./upload_files_controller";
application.register("UploadFilesController", UploadFilesController);

import ModalController from "./modal_controller";
application.register("ModalController", ModalController);

import ReactController from "./react_controller.js";
application.register("react", ReactController);

import DropdownController from "./dropdown_controller.js";
application.register("DropdownController", DropdownController);

import AccordionController from "./accordion_controller.js";
application.register("accordion", AccordionController);

import AccordionMultiController from "./accordion_multi_controller.js";
application.register("accordion-multi", AccordionMultiController);

import TooltipController from "./tooltip_controller.js";
application.register("tooltip", TooltipController);

import CountdownController from "./countdown_controller.js";
application.register("countdown", CountdownController);

import CableOrderController from "./cable_order_controller.js";
application.register("cable-order", CableOrderController);

import PriceDetailController from "./price_detail_controller.js";
application.register("price-detail", PriceDetailController);

import HubspotController from "./hubspot_controller.js";
application.register("hubspot", HubspotController);
