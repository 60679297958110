import { Controller } from "@hotwired/stimulus"
import { Modal } from "flowbite";

export default class extends Controller {
  connect() {
    // console.log("modal controller")
    const element = this.element;
    const modalId = element.getAttribute('data-modal-toggle');
    const modalEl = document.getElementById(modalId);
    const placement = modalEl.getAttribute('data-modal-placement')
  
    if (modalEl) {
      if (!modalEl.hasAttribute('aria-hidden') && !modalEl.hasAttribute('aria-modal')) {
        modalEl.setAttribute('aria-hidden', 'true');
      }
    }
  
    let modal = new Modal(modalEl, {
      placement: placement ? placement : 'center'
    })
  
    element.addEventListener('click', () => {
      modal.toggle()
    })
    
    modalEl.querySelectorAll('[data-modal-toggle]').forEach(el => {
      el.addEventListener('click', () => {
        modal.toggle()
      })
    })
  }
}
