import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

import LanguageButtonList from "./components/LanguageButtonList";
import TargetLanguagesModal from "./components/TargetLanguagesModal";
import {queryFilter} from "../utils/filterHelper";

function convertToOptions(codes) {
  if(codes.length == 0) {
    return null
  }
  
  return codes.map(code => {
    return {
      value: code,
      label: I18n.languages[code] || code,
    }
  }).sort((a, b) => a.label.localeCompare(b.label));
}

const filterOptions = (candidate, input) => {
  return queryFilter(candidate.label, input)
};

function getSelectTheme(theme) {
  return {
    ...theme,
    borderWidth: '1px',
    borderRadius: '0.5rem',
    colors: {
      ...theme.colors,
      primary: '#6159ff0d',
    },
  };
}

const MODAL_SHOW_LIMIT = 6;
// const MODAL_OPEN_VAL = 'dismissible';

export default class LanguagePairSelectStep extends React.Component {
  static propTypes = {
    orderUUID: PropTypes.string.isRequired,
    languageMapping: PropTypes.object,
    sourceLanguageCode: PropTypes.string,
    targetLanguageCodes: PropTypes.array,
  };
  
  constructor(props) {
    super(props);
    this.sourceLanguageOptions = convertToOptions(Object.keys(props.languageMapping));
    // console.log(props.sourceLanguageCode)
    // console.log(props.targetLanguageCodes)
    this.state = {
      // sourceLanguageCode: null, // 'tr'
      // targetLanguageCodes: [], // ['tr']
      sourceLanguageCode: props.sourceLanguageCode, // 'tr'
      targetLanguageCodes: props.targetLanguageCodes, // ['tr']
      openTargetLanguagesModal: undefined, // 'dismissible'
      // sourceLanguageCode: 'tr',
      // targetLanguageCodes: ['tr', 'de'],
    };
  }
  
  setSourceLanguage(val) {
    PushData.pushTrack('translate_from_selected', {source_language: val.label})
    this.setState({
      sourceLanguageCode: val.value,
      targetLanguageCodes: []
    })
  }
  
  setTargetLanguages(values) {
    PushData.pushTrack("translate_to_selected", {languages: values.map(a => a.label)})
    this.setState({
      targetLanguageCodes: values.map(a => a.value)
    })
  }
  
  addToTargetLanguages(codes) {
    PushData.pushTrack("translate_to_selected", {languages: codes.map(a => I18n.languages[a])})
    this.setState({
      targetLanguageCodes: [...new Set(this.state.targetLanguageCodes.concat(codes))]
    })
  }
  
  removeTargetLanguages(values) {
    this.setState({
      targetLanguageCodes: this.state.targetLanguageCodes.filter(code => !values.includes(code))
    })
  }
  
  setOpenTargetLanguagesModal(val) {
    this.setState({
      openTargetLanguagesModal: val
    })
  }
  
  render() {
    const {languageMapping} = this.props;
    const {sourceLanguageCode, targetLanguageCodes, openTargetLanguagesModal} = this.state;
    const targetLanguages = convertToOptions(languageMapping[sourceLanguageCode]?.targets || []) || [];
    const highlightedTargetLanguages = convertToOptions(languageMapping[sourceLanguageCode]?.highlighted_targets || []) || [];
    const popularTargetLanguages = convertToOptions(languageMapping[sourceLanguageCode]?.popular_targets || []) || [];

    return (
      <div className="space-y-6">
        <div>
          <label htmlFor="translateFrom" className="mb-4 block text-base font-semibold text-black">
            {I18n.languagePairSelectSection.translateFrom}
          </label>
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            theme={theme => getSelectTheme(theme)}
            value={convertToOptions(sourceLanguageCode ? [sourceLanguageCode] : [])}
            // isClearable={true}
            // menuIsOpen
            filterOption={filterOptions}
            placeholder={I18n.languagePairSelectSection.pleaseSelect}
            isSearchable={true}
            name="order[source_language_code]"
            options={this.sourceLanguageOptions}
            onChange={this.setSourceLanguage.bind(this)}
            noOptionsMessage={(obj) => I18n.languagePairSelectSection.noOptionsMessage}
          />
        </div>
        
        <div>
          <h6 className="mb-4 text-base font-semibold text-black">
            {I18n.languagePairSelectSection.translateTo}
          </h6>
          
          <div className="min-h-[64px] rounded-lg border border-gray-300 px-4 py-3" onClick={e => {
                if(targetLanguages.length > 0){
                  this.setOpenTargetLanguagesModal('dismissible')
                }
               }}>
            <div id="translateToContent" className="flex flex-wrap gap-2">
              { targetLanguageCodes.map((code, index) => {
                return(
                  <span key={index} className="hover:bg-primary/90 bg-primary focus:ring-primary/80 inline-flex items-center justify-center gap-2 rounded-lg border border-transparent px-3 py-2 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2">
                    {I18n.languages[code]}
                    <button
                      onClick={e => {
                        e.stopPropagation();
                        this.removeTargetLanguages([code])
                      }}
                      type="button">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                           stroke="currentColor" className="h-4 w-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                      </svg>
                    </button>
                    <input type="hidden" name="order[target_language_codes][]" value={code}/>
                  </span>
                )
              }) }
              
            </div>
            { targetLanguageCodes.length === 0 &&
              <input type="hidden" name="order[target_language_codes][]" value=""/>
            }
          </div>
          
          {/*<Select*/}
          {/*  className="react-select-container"*/}
          {/*  classNamePrefix="react-select"*/}
          {/*  theme={theme => getSelectTheme(theme)}*/}
          {/*  isClearable={true}*/}
          {/*  isMulti*/}
          {/*  isSearchable={true}*/}
          {/*  placeholder={I18n.languagePairSelectSection.pleaseSelect}*/}
          {/*  value={convertToOptions(targetLanguageCodes)}*/}
          {/*  name="order[target_language_codes][]"*/}
          {/*  options={convertToOptions(targetLanguages)}*/}
          {/*  onChange={this.setTargetLanguages.bind(this)}*/}
          {/*/>*/}
        </div>
        
        { sourceLanguageCode &&
          <div>
            {popularTargetLanguages.length > 0 &&
              <LanguageButtonList
                languages={popularTargetLanguages}
                title={ I18n.languagePairSelectSection.mostPopular }
                onAdd={this.addToTargetLanguages.bind(this)}
              />
            }
            
            {/*{targetLanguages.length <= MODAL_SHOW_LIMIT &&*/}
            {/*  <LanguageButtonList*/}
            {/*    targetCodes={targetLanguages.filter(x => !popularTargetLanguages.includes(x))}*/}
            {/*    title={popularTargetLanguages.length > 0 ? I18n.languagePairSelectSection.options : I18n.languagePairSelectSection.languages }*/}
            {/*    onAdd={this.addToTargetLanguages.bind(this)}*/}
            {/*  />*/}
            {/*}*/}
            
            <TargetLanguagesModal
              targetLanguageCodes={targetLanguageCodes}
              targetLanguages={targetLanguages}
              modalShowLimit={MODAL_SHOW_LIMIT}
              setOpenTargetLanguagesModal={this.setOpenTargetLanguagesModal.bind(this)}
              openTargetLanguagesModal={openTargetLanguagesModal}
              highlightedTargetLanguages={highlightedTargetLanguages}
              addToTargetLanguages={this.addToTargetLanguages.bind(this)}
              removeTargetLanguages={this.removeTargetLanguages.bind(this)}
            />
          </div>
        }
      </div>
    );
  }
}
