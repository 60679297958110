import request from '../../utils/request'

const postData = (form) => {
  return {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({
      customer_order: form
    })
  }
};

function getInit(object) {
  return {
    method: "POST",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
      'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content
    },
    body: JSON.stringify(object),
  };
}

export const TmpOrderActions = {
  apply_coupon_code: (form, call = null) => {
    return request(`/user/tmp_orders/${form.id}/apply_coupon_code`, call, postData(form))
  },
  remove_coupon_code: (form, call = null) => {
    return request(`/user/tmp_orders/${form.id}/remove_coupon_code`, call, postData(form))
  },
  start_payment: (form, payment_type) => {
    return fetch(`/user/tmp_orders/${form.id}/start_payment`, getInit({ payment_type }))
  },
  payment_callback: (form, payment_intent, payment_type) => {
    return fetch(`/user/tmp_orders/${form.id}/payment_callback`, getInit({
      customer_order: form,
      payment_intent,
      payment_type
    }));
  },
};

export default TmpOrderActions;
