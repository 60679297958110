import request from '../../utils/request';

const postData = (form, form_key = "customer_order") => {
  return {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({
      [form_key]: form
    })
  }
};

function getInit(object) {
  return {
    method: "POST",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
      'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content
    },
    body: JSON.stringify(object),
  };
}

export const PaymentActions = {
  start_payment: (customer_order_id, payment_type) => {
    return fetch(`/user/orders/${customer_order_id}/payments/start_payment`, getInit({ payment_type }))
  },
  payment_callback: (customer_order_id, payment_intent, payment_type) => {
    return fetch(`/user/orders/${customer_order_id}/payments/payment_callback`, getInit({
      payment_intent,
      payment_type
    }));
  },
  save_settings: (customer_order_id, form, call = null) => {
    return request(`/user/orders/${customer_order_id}/payments/save_settings`, call, postData(form))
  },
};
