import PropTypes from "prop-types";
import React from "react";
import { Accordion, Spinner, Alert } from "flowbite-react";
import { PrismicRichText } from "@prismicio/react";
import * as Prismic from '@prismicio/client'

import Moment from "moment/min/moment-with-locales";
import MomentLocalizer from "react-widgets-moment";

const Client = Prismic.createClient('https://harewordtranslation.cdn.prismic.io/api/v2');

// Theme helper
// https://github.com/themesberg/flowbite-react/blob/main/src/components/Accordion/AccordionTitle.tsx

export default class BlogPosts extends React.Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      prepared: false,
      posts: [],
      error: null,
    };
    Moment.locale(props.locale);
    MomentLocalizer.constructor();
  }

  componentDidMount() {
    this.fetchPosts(this.props.locale);
  }

  fetchPosts(locale) {
    const options = {
      orderings: [
        { field: 'my.article.publishDate', direction: 'desc' },
        { field: 'document.first_publication_date', direction: 'desc' },
      ],
      lang: locale,
      pageSize: 3,
    };
    
    Client.getByType('article', options)
      .then((json) => {
        if (json.results.length > 0) {
          this.setState({
            prepared: true,
            posts: json.results,
          });
        } else if (locale !== "en") {
          this.fetchPosts("en");
        }
      })
      .catch((e) => {
        console.warn(e);
        this.setState({
          prepared: true,
          error: I18n.t.error_occurred_in_request,
        });
      });
  }

  render() {
    const { prepared, posts, error } = this.state;

    return (
      <div>
        <div className="rounded-lg bg-white p-4 shadow-[0_3px_8px_2px_rgba(97,89,255,0.1)] md:p-6">
          <h2 className="mb-6 text-xl font-semibold">
            {I18n.analyseSection.latestBlogPosts}
          </h2>

          {!prepared && (
            <div className="text-center">
              <Spinner
                color="gray"
                aria-label="Extra large spinner example"
                size="xl"
              />
            </div>
          )}
          {error && (
            <Alert color="failure">
              <span>{error}</span>
            </Alert>
          )}

          <Accordion
            theme={{ base: "space-y-6" }}
            className="border-none"
            id="blog-posts-accordion"
            collapseAll
          >
            {posts.map((post, index) => {
              const { data } = post;
              const imageUrl = data.featuredImage.url
                ? data.featuredImage.url
                : "/images_v2/blog_post.png";
              return (
                <Accordion.Panel key={index}>
                  <div className="border border-gray-200 rounded-xl">
                    <Accordion.Title
                      as="div"
                      theme={{
                        base: "flex w-full items-center justify-between rounded-xl border border-custom-white p-4 text-left font-medium text-gray-500 lg:p-8 focus:ring-0",
                        open: {
                          off: "accordion-inactive border-none",
                          on: "accordion-active border-none hover:bg-white",
                        },
                      }}
                    >
                      <div className="flex flex-col items-start gap-4 md:flex-row md:gap-6">
                        <img
                          src={imageUrl}
                          width="300"
                          height="150"
                          className="object-cover rounded-2xl"
                          alt={data.featuredImage.alt}
                          srcSet={`${imageUrl} 2x`}
                        />
                        <div className="max-w-[700px]">
                          <div className="flex items-center text-gray-400 text-sm">
                            {Moment(data.publishDate).format(
                              `LL`
                            )}
                          </div>
                          <h2 className="mb-1.5 text-2xl font-semibold leading-8 text-custom-dark-black">
                            {data.title}
                          </h2>
                          <p className="text-base font-light leading-6 text-custom-dark-black">
                            {data.meta_description}
                          </p>
                        </div>
                      </div>
                    </Accordion.Title>
                    <Accordion.Content>
                      <article className="prose max-w-full">
                        <PrismicRichText field={data.slices[0].primary.text} />
                      </article>
                    </Accordion.Content>
                  </div>
                </Accordion.Panel>
              );
            })}
          </Accordion>
        </div>
      </div>
    );
  }
}
