import { Controller } from "@hotwired/stimulus";
import { Tabs } from "flowbite";

// options with default values
const options = {
  defaultTabId: "uploadFiles",
  activeClasses: "bg-primary text-white hover:bg-primary",
  inactiveClasses: "hover:bg-primary text-custom-dark-black",
};

export default class extends Controller {
  connect() {
    // console.log("upload files controller")
    // create an array of objects with the id, trigger element (eg. button), and the content element
    const tabElements = [
      {
        id: "uploadFiles",
        triggerEl: document.querySelector("#upload-your-files-tab"),
        targetEl: document.querySelector("#upload-your-files"),
      },
      {
        id: "typeText",
        triggerEl: document.querySelector("#type-in-your-text-tab"),
        targetEl: document.querySelector("#type-in-your-text"),
      },
    ];
    
    const tabs = new Tabs(tabElements, options);
    tabs.show("uploadFiles");
  }
}
