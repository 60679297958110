import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "seconds", "minutes" ]
  
  connect() {
    this.totalSeconds = this.element.dataset.remainingSeconds;
    
    this.interval = setInterval(() => {
      this.totalSeconds = this.totalSeconds - 1;
      if(this.totalSeconds === 0) {
        clearInterval(this.interval);
      }
      this.writeToDom(this.totalSeconds);
    }, 1000);
  }
  
  writeToDom(totalSeconds) {
    const minutes = parseInt(totalSeconds / 60);
    const seconds = (totalSeconds % 60);
    this.minutesTarget.textContent = minutes > 9 ? minutes : `0${minutes}`;
    this.secondsTarget.textContent = seconds > 9 ? seconds : `0${seconds}`;
  }
  
  disconnect() {
    clearInterval(this.interval);
    // console.log("disconnect");
  }
}
