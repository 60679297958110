import React  from 'react';
import {PAYMENT_TYPE_CURRENT_ACCOUNT} from "../utils/OrderConstants";
import PropTypes from "prop-types";

export default class CurrentAccountPayment extends React.Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    assignFormState: PropTypes.func.isRequired
  };
  
  constructor(props) {
    super(props);
  }
  
  componentDidMount() {
    const { assignFormState } = this.props;
    assignFormState("payment_type_choose", PAYMENT_TYPE_CURRENT_ACCOUNT)
  }
  
  render() {
    const { form, assignFormState } = this.props;
    
    return (
      <div data-inactive-classes="text-custom-gg" data-active-classes="bg-white text-[#656474] border-b-0 rounded-bl-none rounded-br-none" data-accordion="collapse">
        <button type="button"
                id="payment-current-account-heading"
                data-controller="accordion"
                className="flex w-full items-center justify-between rounded-lg border border-gray-200 p-5 text-left"
                data-accordion-target="#payment-current-account-body"
                aria-expanded="true"
                aria-controls="payment-current-account-body">
          <div className="flex items-center gap-4">
            <img src="/images_v2/payment/credit-card.svg" alt=""/>
            <div className="text-left">
              <h6 className="text-lg font-medium text-[#01001A]"> { I18n.payment.current_account_payment_way } </h6>
              {/*<p className="text-sm text-[#4C4B5E]"> Info </p>*/}
            </div>
          </div>
          <svg data-accordion-icon className="h-6 w-6 shrink-0 rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
          </svg>
        </button>
        <div id="payment-current-account-body" className="hidden" aria-labelledby="payment-current-account-heading">
          <div className="border border-t-0 border-gray-200 p-5">
            <div className="space-y-4">
              <p className="text-justify text-[#4C4B5E]"> {I18n.payment.current_account_payment_info} </p>
              <div id="paymentPlan" className="rounded-lg bg-gray-100 p-5">
                <h6 className="mb-4 text-sm font-semibold"> {I18n.payment.invoice_note} </h6>
                <input
                  className="focus:border-primary focus:ring-primary block w-full appearance-none rounded-lg border border-gray-300 bg-white px-4 py-3 text-base text-[#01001A]"
                  value={ form.invoice_note || '' }
                  onChange={(e) => assignFormState("invoice_note", e.target.value)}>
                </input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
