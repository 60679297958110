import PropTypes from 'prop-types';
import React from 'react';
import FormGroupError from './../../partials/FormGroupError';
import {PAYMENT_TYPE_TRANSFER, PAYMENT_TYPE_TRANSFER_VAKIFBANK} from "../../utils/OrderConstants";

export default class TransferForm extends React.Component {
  static propTypes = {
    gateway: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    setPaymentMethod: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { gateway, form, setPaymentMethod, errors } = this.props;

    return (
      <div>
        <button type="button"
                id="payment-heading-transfer"
                className="flex w-full items-center justify-between rounded-lg border border-gray-200 p-5 text-left"
                data-accordion-target="#payment-body-transfer"
                aria-expanded={form.payment_type_choose === gateway.payment_type ? "true" : "false"}
                onClick={e => setPaymentMethod(e.target.closest('button').getAttribute('aria-expanded') === 'true' ? gateway.payment_type : null)}
                aria-controls="payment-body-transfer">
          <div className="flex items-center gap-4">
            <img src="/images_v2/payment/wire-transfer.svg" alt="Transfer"/>
            <div className="text-left">
              <h6 className="text-lg font-medium text-[#01001A]"> { I18n.payment.payment_transfer } </h6>
              <p className="text-sm text-[#4C4B5E]"> { I18n.payment.paymentTransferInfo } </p>
            </div>
          </div>
          <svg data-accordion-icon className="h-6 w-6 shrink-0 rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
          </svg>
        </button>
        <div id="payment-body-transfer" className="hidden" aria-labelledby="payment-heading-transfer">
          <div className="border border-t-0 border-gray-200 p-5">
            <FormGroupError error={errors.payment_type_transfer_value}>
              <div className="relative flex items-center rounded">
                <input id="wire-transfer-vakifbank"
                       type="radio"
                       name="payment_type_transfer"
                       checked={form.payment_type_transfer_value == PAYMENT_TYPE_TRANSFER_VAKIFBANK}
                       onChange={e => setPaymentMethod(PAYMENT_TYPE_TRANSFER, { payment_type_transfer_value: PAYMENT_TYPE_TRANSFER_VAKIFBANK })}
                       className="text-success-green focus:ring-success-green peer absolute left-5 top-7 h-5 w-5 border-gray-300 bg-gray-100 focus:ring-2 md:top-1/2 md:-translate-y-1/2"/>
                <label htmlFor="wire-transfer-vakifbank"
                       className="cursor-pointer peer-checked:border-success-green peer-checked:text-success-green peer-checked:ring-success-green/30 flex w-full items-center justify-between gap-4 rounded-lg border border-gray-200 py-5 pl-14 pr-5 peer-checked:ring-4">
                  <div className="flex w-full flex-col items-start gap-5 md:flex-row md:items-center">
                    <img src="/images_v2/payment/vakifbank.svg" alt="Vakıfbank"/>
                    <div className="max-w-[365px]">
                      <div className="mb-4 text-[#4C4B5E]">
                        <h6 className="mb-1 text-base font-medium leading-none"> Çeviri Hizmetleri A.Ş. </h6>
                        <p className="text-xs"> Türkiye Vakıflar Bankası T.A.O </p>
                      </div>
                      <div>
                        <div className="mb-2 flex items-center gap-4">
                          <span className="text-sm text-[#4C4B5E]"> Şube Kodu: S00437 </span>
                          <span className="text-[#4C4B5E]">
                                    <svg className="h-1 w-1" fill="currentColor" viewBox="0 0 8 8">
                                      <circle cx="4" cy="4" r="3"/>
                                    </svg>
                                  </span>
                          <span className="text-sm text-[#4C4B5E]"> Hesap No: 158007310805667 </span>
                        </div>
                        <span
                          className="text-sm font-medium text-[#01001A]"> IBAN: TR960001500158007310805667 </span>
                      </div>
                      {/*<p dangerouslySetInnerHTML={{__html: gateway.information}}/>*/}
                    </div>
                  </div>
                </label>
              </div>
            </FormGroupError>
          </div>
        </div>
      </div>
    )
  }
}