import PropTypes from "prop-types";
import React from "react";

import OrderSummary from "./components/OrderSummary";
import CurrentAccountPayment from "./components/CurrentAccountPayment";
import PaymentGateWayList from "./components/PaymentGateWayList";
import OrderActions from "./actions/OrderActions";
import WarningMessage from "./components/WarningMessage";
import {PaymentActions} from "./actions/PaymentActions";
import {PAYMENT_TYPE_STRIPE} from "./utils/OrderConstants";

export default class OrderPayment extends React.Component {
  static propTypes = {
    customerOrderId: PropTypes.any.isRequired,
    orderUUID: PropTypes.string.isRequired,
    tmpOrder: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    domain: PropTypes.object.isRequired,
    selection: PropTypes.object.isRequired,
    selectedPackage: PropTypes.object.isRequired,
    postUrl: PropTypes.string.isRequired,
    backLink: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    // Payment related functions
    this.stripeClientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    this.clientPaymentHandle = null;
    this.paymentAmountChanged = null;
    this.paymentReturnUrl = () => {
      return `${window.location.protocol}//${window.location.host}/user/orders/${this.props.customerOrderId}/payments/new`
    }
    this.paymentStartRequest = (form, type) => PaymentActions.start_payment(this.props.customerOrderId, type);
    this.paymentCallbackRequest = (form, intent, type) => PaymentActions.payment_callback(this.props.customerOrderId, intent, type);
    this.paymentCompleted = (json) =>
      Turbo.visit(json.data.path, { action: "replace" });

    // console.log(props.tmpOrder)
    const { data } = props;
    const form = {
      ...props.tmpOrder,
      payment_type_transfer_value: null,
      card_use_threed: false,
      card_save: false,
      card_number: "",
      card_holder_name: "",
      card_expiration_date: "",
      card_cvc2: "",
      temporary_coupon_code: "",
    };
    
    this.state = {
      processing: this.stripeClientSecret === null ? false : true,
      customerAccount: data.customer_account,
      customerPaymentWays: data.customer_payment_ways,
      paymentGateways: data.payment_gateways,
      form: {
        ...form,
        // payment_type_choose: "iyzico"
      },
      errors: {},
      errorMessages: [
        // "Test error message",
        // "Test error message 2",
      ],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.assignFormState = this.assignFormState.bind(this);
    this.setForm = this.setForm.bind(this);
    this.handleErrorResult = this.handleErrorResult.bind(this);
  }

  handleErrorResult(json) {
    let errorMessages = json.error_messages;
    if (json.error) {
      errorMessages.push(json.error);
    }

    this.setState({
      processing: false,
      errors: json.errors,
      errorMessages,
    });
  }
  
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        prepared: true,
      });
    }, 100);
  }

  assignFormState(name, value) {
    let newForm = { ...this.state.form, [name]: value };
    const errors = { ...this.state.errors, [name]: null };
    let extraStates = {
      errors: errors,
    };
  
    if(name === "card_save") {
      const { customerOrderId } = this.props;
      PaymentActions.save_settings(customerOrderId, newForm, (json) => {
        if (json.success) {
          this.processing();
        } else {
          window.toastr.error(json.error || 'Error on save!');
        }
      })
    }
    
    this.setForm(newForm, extraStates);
  }

  setForm(newForm, extraStates = {}) {
    this.setState({
      ...extraStates,
      form: {
        ...newForm,
      },
      errors: {},
      errorMessages: [],
    });
  }

  processing(processing = false, errors = {}, errorMessages = []) {
    this.setState({
      processing: processing,
      errors: errors,
      errorMessages: errorMessages,
    });
  }

  handleSubmit(event) {
    PushData.customerOrder(this.props.customerOrderId, 'Payment Info Entered');
    
    this.setState({
      processing: true,
    });

    const { form } = this.state;
    if(form.payment_type_choose === PAYMENT_TYPE_STRIPE) {
      this.clientPaymentHandle(form);
    } else {
      OrderActions.makePayment(this.props.postUrl, this.state.form, (json) => {
        if (json.success) {
          if (json.payment_completed) {
            return this.paymentCompleted(json);
          }
  
          if (json.threed_redirect) {
            document.write(atob(json.threeDSHtmlContentEncoded));
          } else {
            console.log(`unknown action!`);
          }
        } else {
          this.handleErrorResult(json);
        }
      });
    }
  }

  paymentComponent() {
    const {
      form,
      customerAccount,
      customerPaymentWays,
      paymentGateways,
      errors,
    } = this.state;

    if (customerAccount.is_current_account) {
      return (
        <CurrentAccountPayment
          form={form}
          assignFormState={this.assignFormState}
        />
      );
    }
    return (
      <PaymentGateWayList
        form={form}
        setForm={this.setForm}
        assignFormState={this.assignFormState}
        ctx={this}
        paymentGateways={paymentGateways}
        customerPaymentWays={customerPaymentWays}
        errors={errors}
        i18n={I18n.paymentSection}
        locale={this.props.locale}
      />
    );
  }

  render() {
    const { backLink, tmpOrder, selection, selectedPackage} = this.props;
    const { processing, form, errorMessages } = this.state;

    return (
      <div className="w-full max-w-screen-xl mx-auto">
        <div className="grid gap-8 lg:grid-cols-[1fr,350px]">
          <div className="space-y-6">
            
            <div className="rounded-lg bg-white p-4 shadow-[0_3px_8px_2px_rgba(97,89,255,0.1)] md:p-6">
              <h2 className="mb-4 text-lg font-semibold tracking-wide text-[#0A1629]">
                {I18n.paymentSection.paymentMethod}
              </h2>
              <div
                id="accordion-color"
                className={`${
                  this.stripeClientSecret == null ? "" : "hidden"
                } space-y-6`}
              >
                {this.paymentComponent()}
              </div>
              {this.stripeClientSecret !== null && (
                <div className="text-center">
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      className="inline w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </div>

            {/* Bottom action links */}
            <div className="lg:hidden">
              {errorMessages &&
                errorMessages
                  .slice(0, 2)
                  .map((error, index) => (
                    <WarningMessage key={index} message={error} />
                  ))}
              <div className="flex items-center justify-between gap-2">
                <a
                  href={backLink}
                  disabled={processing}
                  data-turbo-method="post"
                  data-turbo-frame="_top"
                  className="inline-flex w-full items-center justify-center gap-2 rounded-lg bg-custom-dark-black/5 px-6 py-3 text-sm font-medium text-custom-dark-black focus:outline-none focus:ring-2 focus:ring-custom-dark-black/10 focus:ring-offset-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="mr-2 h-5 w-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                    />
                  </svg>
                  {I18n.paymentSection.back}
                </a>
                <button
                  disabled={processing}
                  onClick={this.handleSubmit}
                  type="button"
                  className="bg-primary focus:ring-primary/80 flex w-full items-center justify-center gap-2 rounded-lg px-6 py-3 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  {I18n.paymentSection.makePayment}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          {/* Order summary right bar */}
          <OrderSummary
            locale={this.props.locale}
            backLink={backLink}
            processing={processing}
            handleSubmit={this.handleSubmit}
            i18n={I18n.paymentSection}
            tmpOrder={tmpOrder}
            form={form}
            selectedPackage={selectedPackage}
            selection={selection}
            errorMessages={errorMessages}
            submitText={I18n.paymentSection.makePayment}
          />
        </div>
      </div>
    );
  }
}
